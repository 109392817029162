import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import axios from 'axios';
import { getItem, setItem, removeAll } from './lib/myStore';
import { useHistory } from 'react-router-dom';
// let history = useHistory();


// Add a request interceptor
   axios.interceptors.request.use(
     config => {
       if (config.url.search('amazonaws') === -1) {
         if (getItem('token')) {
          const token = getItem('token');
          if (token) {
            config.headers['Authorization'] = token;
          }
        }
         config.headers['Content-Type'] = 'application/json';
       }
       return config;
     },
     error => {
       Promise.reject(error);
     });

   //Add a response interceptor

axios.interceptors.response.use((response) => {
  /*
    T369 - Session times out after 30 minutes
    Added a condition where, if refreshed token is received in the response, then it will be saved in localstorage
    Added By: Rutuja
    23-09-2020
  */
//  console.log(response);
  if(response.data.token) {
    if (getItem('token')) {
      let getToken = getItem('token');
    }
    
    // console.log(getToken,'getItemgetItem');
    setItem('token', response.data.token);
    setItem('lastActivityAt', new Date());
    // if (getToken && Object.keys(getToken).length)

  }
  if (response.data == 200) {
    setItem('lastActivityAt', new Date());
  }
   return response
}, function (error) {
   const originalRequest = error.config;
   if (error && error.response && error.response.status === 401) {
      window.location = '/';
       return Promise.reject(error);
   } else if (error && error.response && error.response.status === 706) {
     removeAll();
     window.location = '/';
   } else if (error && error.response && error.response.status === 500) {
       /*
         Task: T282 - Error page
         1. something went wrong
         Developer: Manohar Sule
         Date: 31/08/2020
       */
     // removeAll();
     window.location = '/unknown-error';
   }
   return Promise.reject(error);
});

ReactDOM.render(<App />, document.getElementById("root"))
