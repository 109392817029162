/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import { Authenticated } from "./routes/Authenticated"
import { Unauthenticated } from "./routes/Unauthenticated"

import GlobalFonts from "./assets/fonts/fonts"
import axios from "axios"
import CONSTANTS from "./lib/constants"
import { Login } from "./pages/Gateway/Login/Login"
import history from './lib/history';
import moment from "moment"
import {
    Element,
    defaultColours as baseColors
} from "fictoan-react"

import feather from "feather-icons"

import { removeAll, getItem } from "./lib/myStore"

import {
    NotificationItem,
    NotificationsWrapper,
} from "fictoan-react"

//  Switching this will let you access pre-login and post-login screens.
//  Implement custom logic, don’t use this flag!
const isUserAuthenticated = true

// window.addEventListener('beforeunload', function (e) {
//     e.preventDefault();
//     e.returnValue = '';
// });
var validNavigation = 0;


function endSession()
{
   // Browser or Broswer tab is closed
   // Write code here
   alert('Browser or Broswer tab closed');
}

function bindDOMEvents() {
/*

* unload works on both closing tab and on refreshing tab.

*/
window.unload = function()
{
   if (validNavigation==0)
   {
     endSession();
   }
}

// Attach the event keypress to exclude the F5 refresh
window.keydown = function(e)
{
   var key=e.which || e.keyCode;
   if (key == 116)
   {
     validNavigation = 1;
   }
};



}

window.addEventListener("onload", function()
{
   bindDOMEvents();
});

const handleTabClose = event => {
      event.preventDefault();
      console.log('in handleTabClose');
      let pathArray = ['/compliance/finra/core', '/compliance/ffiec/cyber-maturity', '/compliance/finra/cyber-maturity']
      if (pathArray.includes(window.location.pathname)) {
        return;
      }
      return doSomethingBeforeUnload(event);


};

function doSomethingBeforeUnload(event) {
      let token = getItem('token');
      if (token) {
        removeAll()
        axios.post(`${CONSTANTS.API_URL}/api/v1/logout`).then(async (response) => {
          //     // history.push("/?out=true")
          })
        const request = axios.get(`${CONSTANTS.API_URL}/api/v1/logoutAtUserStatisticData/${token}`)
        request
            .then((response) => {
              return (true);
            });
        } else {
          removeAll()
            window.removeEventListener('beforeunload', handleTabClose);
        }

}

window.addEventListener('beforeunload', handleTabClose);

// window.onbeforeunload = function () {
//   console.log('in eeeeeeeeeee');
//   removeAll()
//   axios.post(`${CONSTANTS.API_URL}/api/v1/logout`).then(async (response) => {
//       // history.push("/?out=true")
//   })
// };

let myVar = setInterval(myTimer, 1000);
let myVar1 = setTimeout(() => {
    // getSubscriptionExpireDate();
}, 0)

let lastActivityNotify = false;
let d1 = document.getElementById('lastActivityDiv');
if (d1) d1.style.display = 'none';
// clearInterval(myVar);
function myStopFunction() {
    const request = axios.get(`${CONSTANTS.API_URL}/api/v1/getSessionUpdate`)
    request
        .then((response) => {
            // console.log('responseeeeeee');
        })
    let d3 = document.getElementById('lastActivityDiv');
    if (d3) d3.style.display = 'none';
    clearInterval(myVar);
    setInterval(myTimer, 1000);
  }

  function getSubscriptionExpireDate() {

      let userInfo = getItem('userInfo');
      if (userInfo && userInfo.subscriptionValidTill) {
        console.log('subscription end msg');
        let subscriptionExpireDate = userInfo.subscriptionValidTill ? moment(userInfo.subscriptionValidTill).format("YYYY-MM-DD") : '';
        var given = moment(subscriptionExpireDate, "YYYY-MM-DD");
        var current = moment().startOf('day');
        //Difference in number of days
        let days = moment.duration(given.diff(current)).asDays();
        console.log(days,'subscriptionExpireDate');
        if (days <= 365) {
            let d2 = document.getElementById('subscriptionEnd');

            if (d2) d2.style.display = 'block';
        } else {
            let d2 = document.getElementById('subscriptionEnd');
            if (d2) d2.style.display = 'none';
        }

      }
  }


function myTimer() {
    var d = new Date();
    var t = d.toLocaleTimeString();
    let token = getItem('token');
    let latestActivity = getItem('lastActivityAt');
    if (Object.keys(token).length  && Object.keys(latestActivity).length) {
        const endDate = new Date();
        const today = new Date(getItem('lastActivityAt'));
        const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
        const hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
        const minutes = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60);
        const seconds = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000) % 60);
        // console.log(minutes,'minutes');
        // console.log(seconds,'seconds');
        if (minutes >= '20') {
            let d2 = document.getElementById('lastActivityDiv');
            if (d2) d2.style.display = 'block';
        } else {
            let d3 = document.getElementById('lastActivityDiv');
            if (d3) d3.style.display = 'none';
        }
        if (minutes >= '30') {
            axios.post(`${CONSTANTS.API_URL}/api/v1/logout`).then(async (response) => {
                removeAll()
                // history.push("/?out=true");
                window.location.reload();
                // Route("/")
                // return (
                //     <Router>
                //         <Route path="/" component={Login} />
                //     </Router>
                // )
            })
        }
    }

}

function updateActiveSession() {
    myStopFunction();
}

function closeSubscriptionNotification() {
    let d2 = document.getElementById('subscriptionEnd');
    if (d2) d2.style.display = 'none';
}

class App extends PureComponent {
    render() {
        return (
            <Router>
                <div >
                    <div id='lastActivityDiv' style={{display: 'none'}} className="sc-iJCRLp lastNotify bottom left"><div className="sc-lmgQde lastNotifyDiv warning"><div className="notification-content">Your session is about to expire. Click <a><span onClick={updateActiveSession}>here</span></a> to continue.</div></div></div>
                </div>
                <div >
                    <div id='subscriptionEnd' style={{display: 'none', width: '100%', padding: '0px 20px'}} className="sc-iJCRLp lastNotify top left"><div className="sc-lmgQde lastNotifyDiv warning"><div style={{width: '100%'}} className="notification-content"><Element
                                                as="span"
                                                marginRight="nano"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["alert-triangle"].toSvg({
                                                        width: 14,
                                                        height: 14
                                                    })
                                                }}
                                            />
                                             <span id='expireDays' /><span style={{cursor:'pointer', float: 'right', fontWeight: '600'}} onClick={closeSubscriptionNotification}>X</span></div></div></div>
                </div>
                <GlobalFonts />
                {/*isUserAuthenticated ? (
                    <Route path="/" component={Authenticated} />
                ) : (
                    <Route path="/" component={Unauthenticated} />
                )*/}
                <Route path="/" component={Unauthenticated} />
            </Router>
        )
    }
}

export default App
