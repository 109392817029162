import React, { useState, useEffect } from "react"
import axios from "axios"
import feather from "feather-icons"
import { NavLink } from "react-router-dom"
import { removeAll, getItem, setItem } from "../../../../lib/myStore"
import CONSTANTS from "../../../../lib/constants"
import { getEncryptedData, getDecryptedData, getDecryptedDataHere } from "../../../../lib/encryption"
import { useHistory } from "react-router-dom"

import {
    SidebarWrapper,
    SidebarHeader,
    SidebarItemsGroup,
    SidebarItemsGroupHeader,
    SidebarNestedLinks,
    SidebarItem,
    SidebarItemIcon,
    SidebarItemText,
    HRule,
    Text,
    Portion,
    defaultColours as baseColors,
} from "fictoan-react"

import logo from "../../../images/TD_Blue_Logo.svg"
import icon from "../../../images/TD_Blue_Icon.svg"
import { useActiveLinkSet } from "./utils"
let IRQPATHNAME = ['/compliance/ffiec/irq/technology', '/compliance/ffiec/irq/delivery-channels', '/compliance/ffiec/irq/prod-services', '/compliance/ffiec/irq/org-characteristics', '/compliance/ffiec/irq/external-threats', '/compliance/ffiec/irq/report-overview']
let TDIRQPATHNAME = ['/evaluate/company-details', '/evaluate/data', '/evaluate/regulatory', '/evaluate/technical', '/evaluate/report-overview']
let TDFINRAIRQPATHNAME = ['/compliance/finra/evaluate/company-details', '/compliance/finra/evaluate/data', '/compliance/finra/evaluate/regulatory', '/compliance/finra/evaluate/technical', '/compliance/finra/evaluate/report-overview']
let TDASSESSPATHNAME = ['/assess/level/1', '/assess/level/2', '/assess/level/3', '/assess/level/4', '/assess/level/5','/assess/level/6', '/assess/level/7', '/assess/level/8', '/assess/level/9', '/assess/level/10']

export const Sidebar = () => {
    const userInfo = getItem("userInfo")
    const SUBSCRIPTION = getItem("subscription")
    const [userTenantID] = React.useState(userInfo.tenantID)
    const [userTenantDisplayName, setUserTenantDisplayName] = React.useState(userInfo.tenantDisplayName)
    let history = useHistory()
    const [userRoleList, setUserRoleList] = useState(userInfo.role)
    const [collapsed, setCollapsed] = useState(false)

    const [isEmailVerified, setIsEmailVerified] = useState(false)

    const [isAssess, setIsAssess] = useState(false)

    let [companyDetailPercentage, setCompanyDetailsPercentage] = React.useState(0)
    let [dataPercentage, setDataPercentage] = React.useState(0)
    let [regulatoryInformationPercentage, setRegulatoryInformationPercentage] = React.useState(0)
    let [technicalInformationPercentage, setTechnicalInformationPercentage] = React.useState(0)
    const { activeLinkSet: dynamicActiveLinkSet } = useActiveLinkSet()
    const [activeLinkset, setActiveLinkset] = useState(dynamicActiveLinkSet)
    let [irqquestionpath, setirqquestionpath] = useState('/compliance/ffiec/irq/technology')
    let [tdirqquestionpath, settdirqquestionpath] = useState('/evaluate/company-details')
    let [tdFinrairqquestionpath, settdFinrairqquestionpath] = useState('/compliance/finra/evaluate/company-details')
    let [tdsaqquestionpath, settdsaqquestionpath] = useState('/assess/level/1')
    const [activePathName, setActivePathName] = useState(window.location.pathname)
    const [activeFinraFirst, setActiveFinraFirst] = useState(false)
    let [pathNameArray, setPathNameArray] = useState([])
    const [activeForCompliance, setActiveForCompliance] = useState(false)
    const [currentSubscription, setCurrentSubscription] = useState({})


    useEffect(() => {
      console.log(activeLinkset,'activeLinkset----');
        let tenantID = '';
        if (userInfo.selectedTenantID) {
            tenantID = userInfo.selectedTenantID;
        } else {
            tenantID = userInfo.tenantID;
        }
        if (userInfo) {
            console.log(window.location.pathname,'window.location.pathname', activeLinkset);
            let array = window.location.pathname.split('/');
            console.log(array,'888888888');
            axios.get(`${CONSTANTS.API_URL}/api/v1/tenantSubscriptions/${tenantID}`).then((res) => {
                let decryptedDataForSubscription = getDecryptedData(res.data)
                if (decryptedDataForSubscription){
                    let subData = decryptedDataForSubscription;
                        console.log('decryptedDataForSubscription', subData);
                    setCurrentSubscription(subData)
                    if (userInfo.selectedTenantID) {
                        if (subData.subscriptionType === 'Compliance') {
                            if (subData.subscriptionCompliance && subData.subscriptionCompliance.length) {
                                if (subData.subscriptionCompliance.includes('FFIEC') && subData.subscriptionCompliance.includes('FINRA') && ((window.location.pathname === '/compliance/ffiec') || (array.includes('compliance') && array.includes('ffiec')))) {
                                  console.log('1111111');
                                    setActiveLinkset('linkset-6')
                                }
                                if (subData.subscriptionCompliance.includes('FFIEC') && !subData.subscriptionCompliance.includes('FINRA') && ((window.location.pathname === '/compliance/ffiec') || (array.includes('compliance') && array.includes('ffiec')))) {
                                  console.log('2222222');
                                    setActiveLinkset('linkset-6')
                                }
                                if (!subData.subscriptionCompliance.includes('FFIEC') && subData.subscriptionCompliance.includes('FINRA') && (window.location.pathname === '/compliance/finra')) {
                                  console.log('33333333');
                                    setActiveLinkset('linkset-7')
                                }
                            }

                        }
                    } else {
                        if (subData.subscriptionType === 'Compliance') {
                            if (subData.subscriptionCompliance && subData.subscriptionCompliance.length) {
                                if (subData.subscriptionCompliance.includes('FFIEC') && subData.subscriptionCompliance.includes('FINRA') && ((window.location.pathname === '/compliance/ffiec') || (array.includes('compliance') && array.includes('ffiec')))) {
                                  console.log('44444444');
                                    setActiveLinkset('linkset-2')
                                }
                                if (subData.subscriptionCompliance.includes('FFIEC') && !subData.subscriptionCompliance.includes('FINRA') && ((window.location.pathname === '/compliance/ffiec') || (array.includes('compliance') && array.includes('ffiec')))) {
                                  console.log('55555');
                                    setActiveLinkset('linkset-2')
                                }
                                if (!subData.subscriptionCompliance.includes('FFIEC') && subData.subscriptionCompliance.includes('FINRA') && ((window.location.pathname === '/compliance/finra') || (array.includes('compliance') && array.includes('finra')))) {
                                  console.log('6666666666');
                                    setActiveLinkset('linkset-3')
                                }
                            }

                        }
                    }

                }
            })
        }


    }, [window.location.pathname, userInfo.subscriptionID])


    useEffect(() => {
        setActivePathName(window.location.pathname)
        let array1 = window.location.pathname.split('/');
        if (window.location.pathname !== '/mspDashboard' && !activeForCompliance) {
            if (userInfo && userInfo.selectedTenantID && userInfo.isMSPUser && !(array1.includes('compliance'))) {
                setActiveLinkset('linkset-5')
            }
        }
        if (IRQPATHNAME.includes(window.location.pathname)) {
            setirqquestionpath(window.location.pathname)
        } else {
            setirqquestionpath('/compliance/ffiec/irq/technology')
        }
        if (TDIRQPATHNAME.includes(window.location.pathname)) {
            settdirqquestionpath(window.location.pathname)
        } else {
            settdirqquestionpath('/evaluate/company-details')
        }
        if (TDFINRAIRQPATHNAME.includes(window.location.pathname)) {
            settdFinrairqquestionpath(window.location.pathname)
        } else {
            settdFinrairqquestionpath('/compliance/finra/evaluate/company-details')
        }
        if (TDASSESSPATHNAME.includes(window.location.pathname)) {
            settdsaqquestionpath(window.location.pathname)
        } else {
            settdsaqquestionpath('/assess/level/1')
        }
        let array = (window.location.pathname).split('/');
        setPathNameArray(array)
    },[window.location.pathname]);

    // useEffect(() => {
    //         const request = axios.get(`${CONSTANTS.API_URL}/api/v2/getCountForIRQWizard?tenantID=${userTenantID}`);
    //            request.then( response => {
    //              let decryptedResponseForCategoryWiseCount = response.data;
    //               console.log(decryptedResponseForCategoryWiseCount,'decryptedResponseForCategoryWiseCount');
    //              decryptedResponseForCategoryWiseCount.map((data) =>{
    //                if(data.name === 'Business Information') {
    //                 companyDetailPercentage = ((data.answeredQuestionCount/data.totalQuestionCount)*100);
    //                 setCompanyDetailsPercentage(companyDetailPercentage);
    //                }
    //                if(data.name === 'Data Information') {
    //                  dataPercentage = ((data.answeredQuestionCount/data.totalQuestionCount)*100);
    //                  setDataPercentage(dataPercentage);
    //                }
    //                if(data.name === 'Regulatory Information') {
    //                  regulatoryInformationPercentage = ((data.answeredQuestionCount/data.totalQuestionCount)*100);
    //                  setRegulatoryInformationPercentage(regulatoryInformationPercentage)
    //                }
    //                if(data.name === 'Technical Information') {
    //                  technicalInformationPercentage = ((data.answeredQuestionCount/data.totalQuestionCount)*100);
    //                  setTechnicalInformationPercentage(technicalInformationPercentage);
    //                }
    //              })
    //              console.log(companyDetailPercentage,'companyDetailPercentage');

    //            }).catch( error => {
    //              if(error.response && error.response.data) {

    //              }
    //            })
    // })

    function logout(e) {
        e.preventDefault()
        axios.post(`${CONSTANTS.API_URL}/api/v1/logout`).then(async (response) => {
            removeAll()
            history.push("/?out=true")
            let d2 = document.getElementById('subscriptionEnd');
            if (d2) d2.style.display = 'none';
        })
    }
    function redirectToTerms(e) {
        e.preventDefault()
        history.push("/termsAndConditions");
    }
    function redirectToPolicy(e) {
        e.preventDefault()
        history.push("/privacyPolicy");
    }
    function redirectToMSPTerms(e) {
        e.preventDefault()
        history.push("/mspAdmin/termsAndConditions");
    }
    function redirectToMSPPolicy(e) {
        e.preventDefault()
        history.push("/mspAdmin/privacyPolicy");
    }

    function backToMSPDashboard(e) {
        e.preventDefault()
        if (userInfo.mspUserRoleID && userInfo.mspUserRole) {
            userInfo['roleID'] = userInfo.mspUserRoleID;
            userInfo['role'] = userInfo.mspUserRole;
            setItem('userInfo', userInfo);
            setActiveLinkset("linkset-4")
            history.push("/mspDashboard")
        } else {
            const request = axios.get(`${CONSTANTS.API_URL}/api/v2/users?uuids=${userInfo.uuid}`);
            request.then((response) => {
                console.log(response,'%%%%%%%%%%%%');
                let user = getDecryptedData(response.data);
                userInfo['roleID'] = user[0].roleID;
                userInfo['role'] = user[0].type;
                setItem('userInfo', userInfo);
                setActiveLinkset("linkset-4")
                history.push("/mspDashboard")
            })
        }
    }

    return (
        <>
        {
            userInfo && userInfo.role && (


        <SidebarWrapper id="app-sidebar" className={`no-scrollbar ${collapsed ? "collapsed" : ""}`} style={{'overflowX': 'hidden'}}>
            <SidebarHeader onClick={() => setCollapsed(!collapsed)}>
                <img src={logo} className="header-logo" alt="TD logo" />
                <img src={icon} className="header-icon" alt="TD icon" />
            </SidebarHeader>

            <div id="linkset-wrapper" className={`${activeLinkset}-active`}>
                {
                     userInfo && userInfo.userType && !userInfo.isMSPUser && !userInfo.isKillSwitchOn && (
                        <>
                            <div id="linkset-1" className="linkset" style={{height: 'calc( 100vh - 12vh)'}}>
                                {(currentSubscription && currentSubscription.subscriptionType !== 'Compliance' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor")) && (
                                    <NavLink exact to="/home">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["home"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="600" linkText="Home" />
                                        </SidebarItem>
                                    </NavLink>
                                )}

                                {/* {userInfo.isMSPUser && (activePathName !== '/currentPosture/Dashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer")) && (
                                    <NavLink exact to="/mspDashboard">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["home"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="600" linkText="MSP Dashboard" />
                                        </SidebarItem>
                                    </NavLink>
                                )} */}

                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Evaluate') && currentSubscription.subscriptionFeatures.includes('Evaluate Report') ) && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <SidebarItemsGroup open={TDIRQPATHNAME.includes(window.location.pathname) || activePathName === '/evaluate/report'}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["edit-3"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Evaluate" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        <NavLink exact to={tdirqquestionpath}>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["list"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Questions" />
                                            </SidebarItem>
                                        </NavLink>

                                        <NavLink exact to="/evaluate/report">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["file-text"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Report" />
                                            </SidebarItem>
                                        </NavLink>
                                    </SidebarItemsGroup>
                                )}

                                {
                                    // companyDetailPercentage == 100 && dataPercentage == 100 && regulatoryInformationPercentage == 100 && technicalInformationPercentage == 100 && (
                                        (currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Assess')) && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <SidebarItemsGroup open={TDASSESSPATHNAME.includes(window.location.pathname) || activePathName === '/assess/risks' || activePathName === '/assess/heatmap'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["clipboard"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText linkText="Assess" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to={tdsaqquestionpath}>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["list"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Controls" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink exact to="/assess/risks">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["target"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risk Assessment" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/assess/heatmap">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risk HeatMap" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>
                                    )
                                }

                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Mitigate')) && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <SidebarItemsGroup open={activePathName.includes('mitigate/actions')}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["shield"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Mitigate" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        {/* <NavLink exact to="/mitigate">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Dashboard" />
                                            </SidebarItem>
                                        </NavLink> */}
                                        <NavLink to="/mitigate/actions">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["check-square"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Actions" />
                                            </SidebarItem>
                                        </NavLink>
                                    </SidebarItemsGroup>
                                )}

                                {/*
                                  Bug: Vendor not able access dashboard
                                  Modification: Add Conditional Rendering
                                  By: Masum Raja
                                  Date: 05/May/2023
                                */}

                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Dashboards')) && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor" ||
                                    userInfo.role === "Vendor Management") && (
                                    <SidebarItemsGroup open={activePathName.includes('/currentPosture/Dashboard') || activePathName.includes('assess/threats') || activePathName.includes('compliance/cis/dashboard') || activePathName.includes('/mitigate/dashboard')}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Dashboards" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        {(activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor" ||
                                    userInfo.role === "Vendor Management")) && (
                                    <NavLink exact to="/currentPosture/Dashboard">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["bar-chart"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="Current Posture" />
                                        </SidebarItem>
                                    </NavLink>
                                )}
                                {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor" ||
                                    userInfo.role === "Vendor Management") && (
                                        <NavLink exact to="/mitigate/dashboard">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Plan of Action" />
                                            </SidebarItem>
                                        </NavLink>
                                    )}
                                        {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor" ||
                                    userInfo.role === "Vendor Management") && (
                                    <NavLink exact to="/assess/threats">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["zap"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="Threat Map" />
                                        </SidebarItem>
                                    </NavLink>
                                )}
                                {(activePathName !== '/mspDashboard') && (
                                <NavLink exact to="/compliance/cis/dashboard">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText  weight="400" linkText="CIS Controls v8" />
                                            </SidebarItem>
                                        </NavLink>
                                )}
                                    </SidebarItemsGroup>
                                )}
                                {/* {(activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer")) && (
                                    <NavLink exact to="/userDashboard">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["home"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="600" linkText="User Dashboard" />
                                        </SidebarItem>
                                    </NavLink>
                                )} */}

                                {/* {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin") && (
                                    <NavLink exact to="/assess/threats">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["zap"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="600" linkText="Threat Map" />
                                        </SidebarItem>
                                    </NavLink>
                                )} */}

                                {/*
                                  Bug: Reviewer not able access Third Party Risk
                                  Modification: Add Conditional Rendering
                                  By: Masum Raja
                                  Date: 05/May/2023
                                */}

                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Third Party Risk')) && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" || userInfo.role === "Vendor Management" ||
                                    userInfo.role === "Contributor" || userInfo.role === "Reviewer") && (
                                        <>
                                        {

                                            <HRule className="margin-top-nano margin-bottom-nano" />

                                        }

                                    <NavLink to="/vendors">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["users"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="600" linkText="Third Party Risk" />
                                        </SidebarItem>
                                    </NavLink>
                                    </>
                                )}
                                {/* <HRule className="margin-top-nano margin-bottom-nano" /> */}

                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Reports')) && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor" ||
                                    userInfo.role === "Vendor Management") && (
                                    <>
                                    <HRule className="margin-top-nano margin-bottom-nano" />
                                        <SidebarItemsGroup open={activePathName.includes('/report/controls') || activePathName.includes('/report/risks') || activePathName.includes('/report/mitigate')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'}}
                                                        linkText={`Reports`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink to="/report/controls">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["list"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Controls" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/report/risks">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["target"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risks" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/report/mitigate">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Plan of Action" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink to="/report/cyberHealth">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["check-square"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Cyber Health" />
                                                    </SidebarItem>
                                                </NavLink>
                                        </SidebarItemsGroup>

                                    </>
                                )}

                                {/* {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Cyber Insurance')) && (activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer"  ||
                                    userInfo.role === "Contributor")) && <HRule className="margin-top-nano margin-bottom-nano" />}

                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Cyber Insurance')) && (activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer")) && (

                                    <SidebarItemsGroup >
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["edit-3"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Cyber Insurance" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        <NavLink exact to={'/cyber-insurance-evaluate/demographic'}>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["list"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Evaluate" />
                                            </SidebarItem>
                                        </NavLink>

                                        <NavLink exact to="/cyber-insurance-evaluate/report">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["file-text"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Report" />
                                            </SidebarItem>
                                        </NavLink>
                                    </SidebarItemsGroup>
                                )} */}

                                {((currentSubscription && currentSubscription.subscriptionType !== 'Compliance' && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (activePathName !== '/mspDashboard' && userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" || userInfo.role === "Reviewer")) && <HRule className="margin-top-nano margin-bottom-nano" />}
                                {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (activePathName !== '/mspDashboard' && userInfo.role !== "Vendor Management") && (
                                    <SidebarItemsGroup>
                                    <SidebarItemsGroupHeader>
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["clipboard"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText linkText="Compliance Mode" />
                                        </SidebarItem>
                                    </SidebarItemsGroupHeader>
                                    {
                                        (currentSubscription && currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FFIEC')) && (
                                            <NavLink exact to="/compliance/ffiec">
                                                <SidebarItem
                                                    onClick={() => {
                                                        setActiveLinkset("linkset-2")
                                                    }}
                                                >
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="FFIEC" />
                                                </SidebarItem>
                                            </NavLink>
                                        )
                                    }

                                    {/* {<NavLink exact to="/compliance/cis/dashboard">
                                        <SidebarItem
                                            onClick={() => {
                                                setActiveLinkset("linkset-3")
                                            }}
                                        >
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["check-square"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="CIS" />
                                        </SidebarItem>
                                    </NavLink>} */}

                                    {
                                        (currentSubscription && currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FINRA')) && (

                                        <NavLink exact to="/compliance/finra">
                                            <SidebarItem
                                                onClick={() => {
                                                    setActiveLinkset("linkset-3")
                                                    setActiveFinraFirst(true)
                                                }}
                                            >
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["check-square"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="FINRA" />
                                            </SidebarItem>
                                        </NavLink>
                                        )
                                    }

                                    {/*<NavLink exact to="/compliance/cis">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["check-square"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="CIS" />
                                        </SidebarItem>
                                    </NavLink>

                                    <NavLink exact to="/compliance/nena">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["check-square"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="NENA" />
                                        </SidebarItem>
                                    </NavLink> */}
                                </SidebarItemsGroup>

                                )}


                                <HRule className="margin-top-nano margin-bottom-nano" />

                                {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin") && (
                                    <>
                                        <SidebarItemsGroup open={!isEmailVerified}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    {/*BugHerd Issue #191*/}
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'}}
                                                    title='Admin'
                                                        linkText={`${
                                                            userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                        } Admin`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink to="/admin/org-profile/company-details">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["briefcase"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Org Profile" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/admin/profile/me">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Profile" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/admin/users">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["users"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Users" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                        {/*<HRule marginTop="nano" marginBottom="nano" />

                                            <SidebarItemsGroup>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText linkText={userInfo.displayName} />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                <NavLink exact to="/">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["clock"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText textColour="slate-80" size="tiny" weight="400" linkText="Seen last: 10.24pm, 4d ago" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <NavLink exact to="/">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["monitor"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText textColour="slate-80" size="tiny" weight="400" linkText="Last IP: 204.52.84.105" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <div onClick={logout}>
                                                    <SidebarItem style={{ cursor: "pointer" }}>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["power"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Log out" />
                                                    </SidebarItem>
                                                </div>
                                            </SidebarItemsGroup>*/}
                                    </>
                                )}

                                {(userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <>
                                        <SidebarItemsGroup open={!isEmailVerified}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    {/*BugHerd Issue #191*/}
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'}}
                                                        title={userInfo.role}
                                                        linkText={`${
                                                            userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                        } ${userInfo.role ? userInfo.role : ''}`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            {/* <NavLink to="/admin/org-profile">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["briefcase"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Org profile" />
                                                </SidebarItem>
                                            </NavLink> */}

                                            <NavLink to="/admin/profile/me">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Profile" />
                                                </SidebarItem>
                                            </NavLink>

                                            {/* <NavLink to="/admin/users">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["users"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Users" />
                                                </SidebarItem>
                                            </NavLink> */}
                                        </SidebarItemsGroup>

                                        {/*<HRule marginTop="nano" marginBottom="nano" />

                                            <SidebarItemsGroup>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText linkText={userInfo.displayName} />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                <NavLink exact to="/">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["clock"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText textColour="slate-80" size="tiny" weight="400" linkText="Seen last: 10.24pm, 4d ago" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <NavLink exact to="/">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["monitor"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText textColour="slate-80" size="tiny" weight="400" linkText="Last IP: 204.52.84.105" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <div onClick={logout}>
                                                    <SidebarItem style={{ cursor: "pointer" }}>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["power"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Log out" />
                                                    </SidebarItem>
                                                </div>
                                            </SidebarItemsGroup>*/}
                                    </>
                                )}
                                {(userInfo.role === "Vendor Management") && (
                                    <>
                                        <SidebarItemsGroup open={!isEmailVerified}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    {/*BugHerd Issue #191*/}
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'}}
                                                        title='Vendor Manager'
                                                        linkText={`${
                                                            userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                        } Vendor Manager`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink to="/admin/profile/me">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Profile" />
                                                </SidebarItem>
                                            </NavLink>

                                        </SidebarItemsGroup>

                                    </>
                                )}
                                <HRule marginTop="nano" marginBottom="nano" />

                                <div onClick={logout}>
                                    <SidebarItem style={{ cursor: "pointer" }}>
                                        <SidebarItemIcon
                                            iconType="stroked"
                                            dangerouslySetInnerHTML={{
                                                __html: feather.icons["power"].toSvg({
                                                    width: 16,
                                                    height: 16,
                                                }),
                                            }}
                                        />
                                        <SidebarItemText weight="600" linkText="Logout" />
                                    </SidebarItem>
                                </div>
                                <HRule marginTop="nano" marginBottom="nano" />
                                <div style={{marginTop: '1.3rem'}}>
                                <Text style={{textAlign: 'left',paddingLeft: '2.95rem'}}><span style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}} onClick={redirectToTerms}>Terms</span> & <span onClick={redirectToPolicy} style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}}> Policies</span></Text>
                                </div>
                            </div>

                            {
                                (currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FFIEC')) && activeLinkset === ('linkset-2') && (
                                    <div id="linkset-2" className="linkset">
                                        <SidebarItem
                                            onClick={() => {
                                                setActiveLinkset("linkset-1")
                                            }}
                                        >
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["arrow-left"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText linkText="Back" />
                                        </SidebarItem>

                                        <HRule marginTop="none" marginBottom="nano" />

                                        <NavLink exact to="/compliance/ffiec">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Dashboard" />
                                            </SidebarItem>
                                        </NavLink>

                                        <SidebarItemsGroup open={IRQPATHNAME.includes(window.location.pathname) || activePathName === '/compliance/ffiec/irq/report'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Inherent Risk Profile" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to={irqquestionpath}>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Questions" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/ffiec/irq/report">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Report" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                        <SidebarItemsGroup open={activePathName === '/compliance/ffiec/cyber-maturity' || activePathName === '/compliance/ffiec/cyber-maturity/report' || activePathName === '/compliance/ffiec/cyber-maturity/data-metrics'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Maturity" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to="/compliance/ffiec/cyber-maturity">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Questions" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/ffiec/cyber-maturity/report">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Report" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/ffiec/cyber-maturity/data-metrics">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Data metrics" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                        <SidebarItemsGroup open={activePathName.includes('/compliance/ffiec/poa') || activePathName === '/compliance/ffiec/poa-dash'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Plan of Action" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to="/compliance/ffiec/poa-dash">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Dashboard" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/compliance/ffiec/poa">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Actions" />
                                                </SidebarItem>
                                            </NavLink>

                                        </SidebarItemsGroup>
                                    </div>

                                )
                            }
                            {
                                (currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FINRA')) && activeLinkset === ('linkset-3') && (
                                    <div id="linkset-3" className="linkset">
                                        <SidebarItem
                                            onClick={() => {
                                                setActiveLinkset("linkset-1")
                                            }}
                                        >
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["arrow-left"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText linkText="Back" />
                                        </SidebarItem>

                                        <HRule marginTop="none" marginBottom="nano" />

                                        <NavLink exact to="/compliance/finra">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="FINRA" />
                                            </SidebarItem>
                                        </NavLink>

                                        {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <SidebarItemsGroup open={TDFINRAIRQPATHNAME.includes(window.location.pathname) || activeFinraFirst || activePathName === '/compliance/finra/evaluate/report'}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["edit-3"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Evaluate" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        <NavLink exact to={tdFinrairqquestionpath}>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["list"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Questions" />
                                            </SidebarItem>
                                        </NavLink>

                                        <NavLink exact to="/compliance/finra/evaluate/report">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["file-text"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Report" />
                                            </SidebarItem>
                                        </NavLink>


                                    </SidebarItemsGroup>
                                )}

                                        <SidebarItemsGroup open={activeFinraFirst || pathNameArray.includes('cyber-maturity') || pathNameArray.includes('core') || activePathName === '/compliance/finra/cyber-maturity' || activePathName === '/compliance/finra/cyber-maturity/report' || activePathName === '/compliance/finra/core' || activePathName === '/compliance/finra/assess/risks' || activePathName === '/compliance/finra/assess/heatmap'  || activePathName === '/compliance/finra/mitigate/action' || activePathName.includes('/compliance/finra/mitigate/actions') || activePathName.includes('/vendors')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Cyber Assessment" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to="/compliance/finra/cyber-maturity">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Cyber Checklist" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink exact to="/compliance/finra/core">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Core Controls" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink exact to="/compliance/finra/assess/risks">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["target"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risk Assessment" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/finra/assess/heatmap">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risk HeatMap" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink to="/compliance/finra/mitigate/actions">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["check-square"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Actions" />
                                            </SidebarItem>
                                        </NavLink>
                                        <NavLink to="/vendors">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["users"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="Third Party Risk" />
                                        </SidebarItem>
                                    </NavLink>
                                        </SidebarItemsGroup>
                                        {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <SidebarItemsGroup open={activeFinraFirst || activePathName.includes('/currentPosture/Dashboard') || activePathName.includes('assess/threats') || activePathName.includes('compliance/finra/dashboard') || activePathName.includes('/compliance/finra/mitigate/dashboard')}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Dashboards" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        {(activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor")) && (
                                    <NavLink exact to="/compliance/finra/dashboard">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["bar-chart"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="Current Posture" />
                                        </SidebarItem>
                                    </NavLink>
                                )}
                                {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                        <NavLink exact to="/compliance/finra/mitigate/dashboard">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Plan of Action" />
                                            </SidebarItem>
                                        </NavLink>
                                    )}

                                    </SidebarItemsGroup>
                                )}
                                {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "Contributor") && (
                                    <>
                                    <HRule className="margin-top-nano margin-bottom-nano" />
                                        <SidebarItemsGroup open={activeFinraFirst || activePathName.includes('/compliance/finra/report/controls') || activePathName.includes('/compliance/finra/report/risks') || activePathName.includes('/compliance/finra/report/mitigate')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'}}
                                                        linkText={`Reports`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink to="/compliance/finra/report/controls">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["list"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Controls" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/compliance/finra/report/risks">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["target"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risks" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/compliance/finra/report/mitigate">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Plan of Action" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                    </>
                                )}

                                    </div>

                                )
                            }
                        </>
                    )
                }

                {
                    userInfo && userInfo.userType && userInfo.isMSPUser && !userInfo.isKillSwitchOn && (
                        <>
                            {
                                activeLinkset === ('linkset-4') && (
                                    <div id="linkset-4" className="linkset" style={{height: 'calc( 100vh - 12vh)'}}>
                                        {(activePathName !== '/currentPosture/Dashboard'
                                        // &&
                                        // (userInfo.role === "Tenant Admin" ||
                                        //     userInfo.role === "titanDefClient" ||
                                        //     userInfo.role === "Admin" ||
                                        //     userInfo.role === "User" ||
                                        //     userInfo.role === "Reviewer")
                                            ) && (
                                            <NavLink exact to="/mspDashboard">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["home"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="600" linkText="MSP Dashboard" />
                                                </SidebarItem>
                                            </NavLink>
                                        )}
                                        <HRule className="margin-top-nano margin-bottom-nano" />

                                        {(userInfo.role === "Tenant Admin" ||
                                            userInfo.role === "titanDefClient" ||
                                            userInfo.role === "Admin") && (
                                            <>
                                                <SidebarItemsGroup open={!isEmailVerified}>
                                                    <SidebarItemsGroupHeader>
                                                        <SidebarItem>
                                                            <SidebarItemIcon
                                                                iconType="stroked"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: feather.icons["user"].toSvg({
                                                                        width: 16,
                                                                        height: 16,
                                                                    }),
                                                                }}
                                                            />
                                                            {/*BugHerd Issue #191*/}
                                                            <SidebarItemText
                                                                style={{width: '160px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'}}
                                                            title='Admin'
                                                                linkText={`${
                                                                    userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                                } Admin`}
                                                            />
                                                        </SidebarItem>
                                                    </SidebarItemsGroupHeader>

                                                    <NavLink to="/mspAdmin/org-profile/company-details">
                                                        <SidebarItem>
                                                            <SidebarItemIcon
                                                                iconType="stroked"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: feather.icons["briefcase"].toSvg({
                                                                        width: 16,
                                                                        height: 16,
                                                                    }),
                                                                }}
                                                            />
                                                            <SidebarItemText weight="400" linkText="Org Profile" />
                                                        </SidebarItem>
                                                    </NavLink>

                                                    <NavLink to="/mspAdmin/profile/me">
                                                        <SidebarItem>
                                                            <SidebarItemIcon
                                                                iconType="stroked"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: feather.icons["user"].toSvg({
                                                                        width: 16,
                                                                        height: 16,
                                                                    }),
                                                                }}
                                                            />
                                                            <SidebarItemText weight="400" linkText="Profile" />
                                                        </SidebarItem>
                                                    </NavLink>

                                                    <NavLink to="/mspAdmin/users">
                                                        <SidebarItem>
                                                            <SidebarItemIcon
                                                                iconType="stroked"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: feather.icons["users"].toSvg({
                                                                        width: 16,
                                                                        height: 16,
                                                                    }),
                                                                }}
                                                            />
                                                            <SidebarItemText weight="400" linkText="Users" />
                                                        </SidebarItem>
                                                    </NavLink>
                                                </SidebarItemsGroup>

                                                {/*<HRule marginTop="nano" marginBottom="nano" />

                                                    <SidebarItemsGroup>
                                                        <SidebarItemsGroupHeader>
                                                            <SidebarItem>
                                                                <SidebarItemIcon
                                                                    iconType="stroked"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: feather.icons["user"].toSvg({
                                                                            width: 16,
                                                                            height: 16,
                                                                        }),
                                                                    }}
                                                                />
                                                                <SidebarItemText linkText={userInfo.displayName} />
                                                            </SidebarItem>
                                                        </SidebarItemsGroupHeader>

                                                        <NavLink exact to="/">
                                                            <SidebarItem>
                                                                <SidebarItemIcon
                                                                    iconType="stroked"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: feather.icons["clock"].toSvg({
                                                                            width: 16,
                                                                            height: 16,
                                                                        }),
                                                                    }}
                                                                />
                                                                <SidebarItemText textColour="slate-80" size="tiny" weight="400" linkText="Seen last: 10.24pm, 4d ago" />
                                                            </SidebarItem>
                                                        </NavLink>

                                                        <NavLink exact to="/">
                                                            <SidebarItem>
                                                                <SidebarItemIcon
                                                                    iconType="stroked"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: feather.icons["monitor"].toSvg({
                                                                            width: 16,
                                                                            height: 16,
                                                                        }),
                                                                    }}
                                                                />
                                                                <SidebarItemText textColour="slate-80" size="tiny" weight="400" linkText="Last IP: 204.52.84.105" />
                                                            </SidebarItem>
                                                        </NavLink>

                                                        <div onClick={logout}>
                                                            <SidebarItem style={{ cursor: "pointer" }}>
                                                                <SidebarItemIcon
                                                                    iconType="stroked"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: feather.icons["power"].toSvg({
                                                                            width: 16,
                                                                            height: 16,
                                                                        }),
                                                                    }}
                                                                />
                                                                <SidebarItemText weight="400" linkText="Log out" />
                                                            </SidebarItem>
                                                        </div>
                                                    </SidebarItemsGroup>*/}
                                            </>
                                        )}

                                    {(userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <>
                                            <SidebarItemsGroup open={!isEmailVerified}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        {/*BugHerd Issue #191*/}
                                                        <SidebarItemText
                                                            style={{width: '160px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'}}
                                                            title={userInfo.role}
                                                            linkText={`${
                                                                userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                            } ${userInfo.role ? userInfo.role : ''}`}
                                                        />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                <NavLink to="/mspAdmin/profile/me">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Profile" />
                                                    </SidebarItem>
                                                </NavLink>


                                            </SidebarItemsGroup>


                                        </>
                                    )}
                                    {(userInfo.role === "Vendor Management") && (
                                        <>
                                            <SidebarItemsGroup open={!isEmailVerified}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        {/*BugHerd Issue #191*/}
                                                        <SidebarItemText
                                                            style={{width: '160px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'}}
                                                            title='Vendor Manager'
                                                            linkText={`${
                                                                userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                            } Vendor Manager`}
                                                        />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                <NavLink to="/mspAdmin/profile/me">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Profile" />
                                                    </SidebarItem>
                                                </NavLink>

                                            </SidebarItemsGroup>

                                        </>
                                    )}




                                        <HRule marginTop="nano" marginBottom="nano" />

                                        <div onClick={logout}>
                                            <SidebarItem style={{ cursor: "pointer" }}>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["power"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="600" linkText="Logout" />
                                            </SidebarItem>
                                        </div>
                                        <HRule marginTop="nano" marginBottom="nano" />
                                        <div style={{marginTop: '1.3rem'}}>
                                        <Text style={{textAlign: 'left',paddingLeft: '2.95rem'}}><span style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}} onClick={redirectToMSPTerms}>Terms</span> & <span onClick={redirectToMSPPolicy} style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}}> Policies</span></Text>
                                        </div>

                                    </div>
                            )}
                            {
                                activeLinkset === ('linkset-5') && (
                                    <div id="linkset-5" className="linkset" style={{height: 'calc( 100vh - 12vh)'}}>
                                        <SidebarItem
                                            onClick={(e) => {
                                                backToMSPDashboard(e)

                                            }}
                                        >
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["arrow-left"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText linkText="Back" />
                                        </SidebarItem>

                                        <HRule marginTop="none" marginBottom="nano" />
                                    {(currentSubscription && currentSubscription.subscriptionType !== 'Compliance') && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <NavLink exact to="/home">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="600" linkText={`Home (${userInfo.tenantDisplayName})`} />
                                            </SidebarItem>
                                        </NavLink>
                                    )}

                                    {/* {userInfo.isMSPUser && (activePathName !== '/currentPosture/Dashboard' && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer")) && (
                                        <NavLink exact to="/mspDashboard">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="600" linkText="MSP Dashboard" />
                                            </SidebarItem>
                                        </NavLink>
                                    )} */}

                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Evaluate') && currentSubscription.subscriptionFeatures.includes('Evaluate Report') ) && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <SidebarItemsGroup open={TDIRQPATHNAME.includes(window.location.pathname) || activePathName === '/evaluate/report'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["edit-3"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText linkText="Evaluate" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to={tdirqquestionpath}>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["list"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Questions" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/evaluate/report">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Report" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>
                                    )}

                                    {
                                        // companyDetailPercentage == 100 && dataPercentage == 100 && regulatoryInformationPercentage == 100 && technicalInformationPercentage == 100 && (
                                            (currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Assess') ) && (userInfo.role === "Tenant Admin" ||
                                            userInfo.role === "titanDefClient" ||
                                            userInfo.role === "Admin" ||
                                            userInfo.role === "User" ||
                                            userInfo.role === "Reviewer" ||
                                            userInfo.role === "Contributor") && (
                                            <SidebarItemsGroup open={TDASSESSPATHNAME.includes(window.location.pathname) || activePathName === '/assess/risks' || activePathName === '/assess/heatmap'}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["clipboard"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText linkText="Assess" />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                <NavLink exact to={tdsaqquestionpath}>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["list"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Controls" />
                                                    </SidebarItem>
                                                </NavLink>
                                                <NavLink exact to="/assess/risks">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["target"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Risk Assessment" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <NavLink exact to="/assess/heatmap">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["bar-chart"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Risk HeatMap" />
                                                    </SidebarItem>
                                                </NavLink>
                                            </SidebarItemsGroup>
                                        )
                                    }

                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Mitigate') ) && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <SidebarItemsGroup open={activePathName.includes('mitigate/actions')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["shield"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText linkText="Mitigate" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>


                                            <NavLink to="/mitigate/actions">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Actions" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>
                                    )}
                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Dashboards') ) && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <SidebarItemsGroup open={activePathName.includes('/currentPosture/Dashboard') || activePathName.includes('assess/threats') || activePathName.includes('compliance/cis/dashboard') || activePathName.includes('/mitigate/dashboard')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["home"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText linkText="Dashboards" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            {(activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor")) && (
                                        <NavLink exact to="/currentPosture/Dashboard">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Current Posture" />
                                            </SidebarItem>
                                        </NavLink>
                                    )}
                                    {(userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                            <NavLink exact to="/mitigate/dashboard">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Plan of Action" />
                                                </SidebarItem>
                                            </NavLink>
                                        )}
                                            {(userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <NavLink exact to="/assess/threats">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["zap"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Threat Map" />
                                            </SidebarItem>
                                        </NavLink>
                                    )}
                                    {(activePathName !== '/mspDashboard' && userInfo.role !== "Vendor Management") && (
                                    <NavLink exact to="/compliance/cis/dashboard">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText  weight="400" linkText="CIS Controls v8" />
                                                </SidebarItem>
                                            </NavLink>
                                    )}
                                        </SidebarItemsGroup>
                                    )}

                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Third Party Risk') ) && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" || userInfo.role === "Vendor Management" ||
                                        userInfo.role === "Contributor") && (
                                            <>
                                            {
                                                userInfo.role !== "Vendor Management" && (
                                                    <HRule className="margin-top-nano margin-bottom-nano" />
                                                )
                                            }

                                        <NavLink to="/vendors">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["users"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="600" linkText="Third Party Risk" />
                                            </SidebarItem>
                                        </NavLink>
                                        </>
                                    )}
                                    {/* <HRule className="margin-top-nano margin-bottom-nano" /> */}

                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Reports') ) && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <>
                                        <HRule className="margin-top-nano margin-bottom-nano" />
                                            <SidebarItemsGroup open={activePathName.includes('/report/controls') || activePathName.includes('/report/risks') || activePathName.includes('/report/mitigate')}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText
                                                            style={{width: '160px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'}}
                                                            linkText={`Reports`}
                                                        />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                <NavLink to="/report/controls">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["list"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Controls" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <NavLink to="/report/risks">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["target"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Risks" />
                                                    </SidebarItem>
                                                </NavLink>

                                                <NavLink to="/report/mitigate">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["check-square"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Plan of Action" />
                                                    </SidebarItem>
                                                </NavLink>
                                                <NavLink to="/report/cyberHealth">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["check-square"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Cyber Health" />
                                                    </SidebarItem>
                                                </NavLink>
                                            </SidebarItemsGroup>

                                        </>
                                    )}

                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Cyber Insurance') ) && (activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer"  ||
                                        userInfo.role === "Contributor")) && <HRule className="margin-top-nano margin-bottom-nano" />}

                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Cyber Insurance') ) && (activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" ||
                                        userInfo.role === "User" ||
                                        userInfo.role === "Reviewer")) && (

                                        <SidebarItemsGroup >
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["edit-3"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText linkText="Cyber Insurance" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to={'/cyber-insurance-evaluate/demographic'}>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["list"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Evaluate" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/cyber-insurance-evaluate/report">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Report" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>
                                    )}

                                    {((currentSubscription && currentSubscription.subscriptionType !== 'Compliance' && currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (activePathName !== '/mspDashboard' && userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin" || userInfo.role === "Reviewer")) && <HRule className="margin-top-nano margin-bottom-nano" />}
                                    {(currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (activePathName !== '/mspDashboard' && userInfo.role !== "Vendor Management") && (
                                        <SidebarItemsGroup>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["clipboard"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Compliance Mode" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        {
                                            (currentSubscription && currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FFIEC')) && (
                                                <NavLink exact to="/compliance/ffiec">
                                                    <SidebarItem
                                                        onClick={() => {
                                                            setActiveLinkset("linkset-6")
                                                            setActiveForCompliance(true)
                                                        }}
                                                    >
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["check-square"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="FFIEC" />
                                                    </SidebarItem>
                                                </NavLink>
                                            )
                                        }

                                        {
                                            (currentSubscription && currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FINRA')) && (
                                                <NavLink exact to="/compliance/finra">
                                                    <SidebarItem
                                                        onClick={() => {
                                                            setActiveLinkset("linkset-7")
                                                            setActiveForCompliance(true)
                                                            setActiveFinraFirst(true)
                                                        }}
                                                    >
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["check-square"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="FINRA" />
                                                    </SidebarItem>
                                                </NavLink>
                                            )
                                        }
                                    </SidebarItemsGroup>

                                    )}


                                    <HRule className="margin-top-nano margin-bottom-nano" />

                                    {(userInfo.role === "Tenant Admin" ||
                                        userInfo.role === "titanDefClient" ||
                                        userInfo.role === "Admin") && (
                                        <>
                                            <SidebarItemsGroup open={!isEmailVerified}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        {/*BugHerd Issue #191*/}
                                                        <SidebarItemText
                                                            style={{width: '160px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'}}
                                                        title='Admin'
                                                            linkText={`${
                                                                userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                            } Admin`}
                                                        />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                {/* <NavLink to="/admin/org-profile/company-details">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["briefcase"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Org Profile" />
                                                    </SidebarItem>
                                                </NavLink> */}

                                                {/* <NavLink to="/admin/profile/me">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Profile" />
                                                    </SidebarItem>
                                                </NavLink> */}

                                                <NavLink to="/admin/users">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["users"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Users" />
                                                    </SidebarItem>
                                                </NavLink>
                                            </SidebarItemsGroup>


                                        </>
                                    )}

                                    {(userInfo.role === "Reviewer" ||
                                        userInfo.role === "Contributor") && (
                                        <>
                                            <SidebarItemsGroup open={!isEmailVerified}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        {/*BugHerd Issue #191*/}
                                                        <SidebarItemText
                                                            style={{width: '160px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'}}
                                                            title={userInfo.role}
                                                            linkText={`${
                                                                userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                            } ${userInfo.role ? userInfo.role : ''}`}
                                                        />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                {/* <NavLink to="/admin/org-profile">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["briefcase"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Org profile" />
                                                    </SidebarItem>
                                                </NavLink> */}

                                                {/* <NavLink to="/admin/profile/me">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Profile" />
                                                    </SidebarItem>
                                                </NavLink> */}


                                            </SidebarItemsGroup>


                                        </>
                                    )}
                                    {(userInfo.role === "Vendor Management") && (
                                        <>
                                            <SidebarItemsGroup open={!isEmailVerified}>
                                                <SidebarItemsGroupHeader>
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        {/*BugHerd Issue #191*/}
                                                        <SidebarItemText
                                                            style={{width: '160px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'}}
                                                            title='Vendor Manager'
                                                            linkText={`${
                                                                userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                            } Vendor Manager`}
                                                        />
                                                    </SidebarItem>
                                                </SidebarItemsGroupHeader>

                                                {/* <NavLink to="/admin/profile/me">
                                                    <SidebarItem>
                                                        <SidebarItemIcon
                                                            iconType="stroked"
                                                            dangerouslySetInnerHTML={{
                                                                __html: feather.icons["user"].toSvg({
                                                                    width: 16,
                                                                    height: 16,
                                                                }),
                                                            }}
                                                        />
                                                        <SidebarItemText weight="400" linkText="Profile" />
                                                    </SidebarItem>
                                                </NavLink> */}

                                            </SidebarItemsGroup>

                                        </>
                                    )}
                                    <HRule marginTop="nano" marginBottom="nano" />

                                    <div onClick={logout}>
                                        <SidebarItem style={{ cursor: "pointer" }}>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["power"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="600" linkText="Logout" />
                                        </SidebarItem>
                                    </div>
                                    <HRule marginTop="nano" marginBottom="nano" />
                                    <div style={{marginTop: '1.3rem'}}>
                                    <Text style={{textAlign: 'left',paddingLeft: '2.95rem'}}><span style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}} onClick={redirectToTerms}>Terms</span> & <span onClick={redirectToPolicy} style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}}> Policies</span></Text>
                                    </div>
                                </div>
                                )
                            }
                            {
                                (currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FFIEC')) && activeLinkset === ('linkset-6') && (
                                    <div id="linkset-6" className="linkset" style={{height: 'calc( 100vh - 12vh)'}}>
                                        <SidebarItem
                                            onClick={() => {
                                                setActiveForCompliance(false)
                                                setActiveLinkset("linkset-5")
                                            }}
                                        >
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["arrow-left"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText linkText="Back" />
                                        </SidebarItem>

                                        <HRule marginTop="none" marginBottom="nano" />

                                        <NavLink exact to="/compliance/ffiec">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Dashboard" />
                                            </SidebarItem>
                                        </NavLink>

                                        <SidebarItemsGroup open={IRQPATHNAME.includes(window.location.pathname) || activePathName === '/compliance/ffiec/irq/report'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Inherent Risk Profile" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to={irqquestionpath}>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Questions" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/ffiec/irq/report">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Report" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                        <SidebarItemsGroup open={activePathName === '/compliance/ffiec/cyber-maturity' || activePathName === '/compliance/ffiec/cyber-maturity/report' || activePathName === '/compliance/ffiec/cyber-maturity/data-metrics'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Maturity" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to="/compliance/ffiec/cyber-maturity">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Questions" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/ffiec/cyber-maturity/report">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Report" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/ffiec/cyber-maturity/data-metrics">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["file-text"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Data metrics" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                        <SidebarItemsGroup open={activePathName.includes('/compliance/ffiec/poa') || activePathName === '/compliance/ffiec/poa-dash'}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Plan of Action" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to="/compliance/ffiec/poa-dash">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Dashboard" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/compliance/ffiec/poa">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Actions" />
                                                </SidebarItem>
                                            </NavLink>

                                        </SidebarItemsGroup>
                                    </div>

                                )
                            }
                            {
                                (currentSubscription && currentSubscription.subscriptionFeatures && currentSubscription.subscriptionFeatures.includes('Compliance')) && (currentSubscription.subscriptionCompliance && currentSubscription.subscriptionCompliance.includes('FINRA')) && activeLinkset === ('linkset-7') && (
                                    <div id="linkset-7" className="linkset" style={{height: 'calc( 100vh - 12vh)'}}>
                                        <SidebarItem
                                            onClick={() => {
                                                setActiveForCompliance(false)
                                                setActiveLinkset("linkset-5")
                                            }}
                                        >
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["arrow-left"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText linkText="Back" />
                                        </SidebarItem>

                                        <HRule marginTop="none" marginBottom="nano" />

                                        <NavLink exact to="/compliance/finra">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="FINRA" />
                                            </SidebarItem>
                                        </NavLink>

                                        {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <SidebarItemsGroup open={TDFINRAIRQPATHNAME.includes(window.location.pathname) || activeFinraFirst || activePathName === '/compliance/finra/evaluate/report'}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["edit-3"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Evaluate" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        <NavLink exact to={tdFinrairqquestionpath}>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["list"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Questions" />
                                            </SidebarItem>
                                        </NavLink>

                                        <NavLink exact to="/compliance/finra/evaluate/report">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["file-text"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Report" />
                                            </SidebarItem>
                                        </NavLink>


                                    </SidebarItemsGroup>
                                )}

                                        <SidebarItemsGroup open={activeFinraFirst || pathNameArray.includes('cyber-maturity') || pathNameArray.includes('core') || activePathName === '/compliance/finra/cyber-maturity' || activePathName === '/compliance/finra/cyber-maturity/report' || activePathName === '/compliance/finra/core' || activePathName === '/compliance/finra/assess/risks' || activePathName === '/compliance/finra/assess/heatmap'  || activePathName === '/compliance/finra/mitigate/action' || activePathName.includes('/compliance/finra/mitigate/actions') || activePathName.includes('/vendors')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Cyber Assessment" />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink exact to="/compliance/finra/cyber-maturity">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Cyber Checklist" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink exact to="/compliance/finra/core">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Core Controls" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink exact to="/compliance/finra/assess/risks">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["target"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risk Assessment" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink exact to="/compliance/finra/assess/heatmap">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["bar-chart"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risk HeatMap" />
                                                </SidebarItem>
                                            </NavLink>
                                            <NavLink to="/compliance/finra/mitigate/actions">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["check-square"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Actions" />
                                            </SidebarItem>
                                        </NavLink>
                                        <NavLink to="/vendors">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["users"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="Third Party Risk" />
                                        </SidebarItem>
                                    </NavLink>
                                        </SidebarItemsGroup>
                                        {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <SidebarItemsGroup open={activeFinraFirst || activePathName.includes('/currentPosture/Dashboard') || activePathName.includes('assess/threats') || activePathName.includes('compliance/finra/dashboard') || activePathName.includes('/compliance/finra/mitigate/dashboard')}>
                                        <SidebarItemsGroupHeader>
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["home"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText linkText="Dashboards" />
                                            </SidebarItem>
                                        </SidebarItemsGroupHeader>

                                        {(activePathName !== '/mspDashboard' && (userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor")) && (
                                    <NavLink exact to="/compliance/finra/dashboard">
                                        <SidebarItem>
                                            <SidebarItemIcon
                                                iconType="stroked"
                                                dangerouslySetInnerHTML={{
                                                    __html: feather.icons["bar-chart"].toSvg({
                                                        width: 16,
                                                        height: 16,
                                                    }),
                                                }}
                                            />
                                            <SidebarItemText weight="400" linkText="Current Posture" />
                                        </SidebarItem>
                                    </NavLink>
                                )}
                                {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "User" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                        <NavLink exact to="/compliance/finra/mitigate/dashboard">
                                            <SidebarItem>
                                                <SidebarItemIcon
                                                    iconType="stroked"
                                                    dangerouslySetInnerHTML={{
                                                        __html: feather.icons["bar-chart"].toSvg({
                                                            width: 16,
                                                            height: 16,
                                                        }),
                                                    }}
                                                />
                                                <SidebarItemText weight="400" linkText="Plan of Action" />
                                            </SidebarItem>
                                        </NavLink>
                                    )}

                                    </SidebarItemsGroup>
                                )}
                                {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin" ||
                                    userInfo.role === "Reviewer" ||
                                    userInfo.role === "Contributor") && (
                                    <>
                                    <HRule className="margin-top-nano margin-bottom-nano" />
                                        <SidebarItemsGroup open={activeFinraFirst || activePathName.includes('/compliance/finra/report/controls') || activePathName.includes('/compliance/finra/report/risks') || activePathName.includes('/compliance/finra/report/mitigate')}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'}}
                                                        linkText={`Reports`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink to="/compliance/finra/report/controls">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["list"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Controls" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/compliance/finra/report/risks">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["target"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Risks" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/compliance/finra/report/mitigate">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["check-square"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Plan of Action" />
                                                </SidebarItem>
                                            </NavLink>
                                        </SidebarItemsGroup>

                                    </>
                                )}

                                    </div>

                                )
                            }

                        </>
                    )
                }
                {
                    userInfo && userInfo.userType && userInfo.isKillSwitchOn && (
                        <>
                            <div id="linkset-1" className="linkset" style={{height: 'calc( 100vh - 12vh)'}}>
                            {(userInfo.role === "Tenant Admin" ||
                                    userInfo.role === "titanDefClient" ||
                                    userInfo.role === "Admin") && (
                                    <>
                                        <SidebarItemsGroup open={!isEmailVerified}>
                                            <SidebarItemsGroupHeader>
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    {/*BugHerd Issue #191*/}
                                                    <SidebarItemText
                                                        style={{width: '160px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'}}
                                                    title='Admin'
                                                        linkText={`${
                                                            userInfo.displayName ? userInfo.displayName.split(" ")[0] + " - " : ""
                                                        } Admin`}
                                                    />
                                                </SidebarItem>
                                            </SidebarItemsGroupHeader>

                                            <NavLink to="/admin/org-profile/subscription-details">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["briefcase"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Org Profile" />
                                                </SidebarItem>
                                            </NavLink>

                                            {/* <NavLink to="/admin/profile/me">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["user"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Profile" />
                                                </SidebarItem>
                                            </NavLink>

                                            <NavLink to="/admin/users">
                                                <SidebarItem>
                                                    <SidebarItemIcon
                                                        iconType="stroked"
                                                        dangerouslySetInnerHTML={{
                                                            __html: feather.icons["users"].toSvg({
                                                                width: 16,
                                                                height: 16,
                                                            }),
                                                        }}
                                                    />
                                                    <SidebarItemText weight="400" linkText="Users" />
                                                </SidebarItem>
                                            </NavLink> */}
                                        </SidebarItemsGroup>
                                        <HRule marginTop="nano" marginBottom="nano" />

                                <div onClick={logout}>
                                    <SidebarItem style={{ cursor: "pointer" }}>
                                        <SidebarItemIcon
                                            iconType="stroked"
                                            dangerouslySetInnerHTML={{
                                                __html: feather.icons["power"].toSvg({
                                                    width: 16,
                                                    height: 16,
                                                }),
                                            }}
                                        />
                                        <SidebarItemText weight="600" linkText="Logout" />
                                    </SidebarItem>
                                </div>
                                <HRule marginTop="nano" marginBottom="nano" />
                                <div style={{marginTop: '1.3rem'}}>
                                <Text style={{textAlign: 'left',paddingLeft: '2.95rem'}}><span style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}} onClick={redirectToTerms}>Terms</span> & <span onClick={redirectToPolicy} style={{fontWeight: '100', color: '#2874a6', cursor:'pointer'}}> Policies</span></Text>
                                </div>


                                    </>
                                )}
                            </div>
                        </>
                    )
                }














                {/* {
                    activeLinkset === ('linkset-3') && (
                        <div id="linkset-3" className="linkset">
                            <SidebarItem
                                onClick={() => {
                                    setActiveLinkset("linkset-1")
                                }}
                            >
                                <SidebarItemIcon
                                    iconType="stroked"
                                    dangerouslySetInnerHTML={{
                                        __html: feather.icons["arrow-left"].toSvg({
                                            width: 16,
                                            height: 16,
                                        }),
                                    }}
                                />
                                <SidebarItemText linkText="Back" />
                            </SidebarItem>

                            <HRule marginTop="none" marginBottom="nano" />

                            <NavLink exact to="/compliance/cis/dashboard">
                                <SidebarItem>
                                    <SidebarItemIcon
                                        iconType="stroked"
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons["bar-chart"].toSvg({
                                                width: 16,
                                                height: 16,
                                            }),
                                        }}
                                    />
                                    <SidebarItemText linkText="Dashboard" />
                                </SidebarItem>
                            </NavLink>

                        </div>

                    )
                } */}



            </div>
        </SidebarWrapper>
        )
        }
    </>
        )
}
