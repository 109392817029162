/* eslint-disable react/prefer-stateless-function */
import React, { Suspense, PureComponent } from "react"
import { Switch, Route } from "react-router-dom"
import { ContentWrapper, ThemeProvider } from "fictoan-react"

import { Sidebar } from "../assets/components/Global/Sidebar/Sidebar"

import { TDTheme } from "../styles/Theme"
import { GlobalsStyle } from "../styles/Globals.styled"

// //  CORE  /////////////////////////////////////////////////////////////////////
// import { Home } from "../pages/Home/Home"

// //  IRQ  //////////////////////////////////////////////////////////////////////
// import { IRQ } from "../pages/Risk-Evaluate-IRQ/IRQ"
// import { IRQReport } from "../pages/Risk-Evaluate-IRQ/DetailedReport"

// //  SAQ  //////////////////////////////////////////////////////////////////////
// import { SAQ } from "../pages/Risk-Assess-SAQ/SAQ"

// //  ADMIN  ////////////////////////////////////////////////////////////////////
// import { Users } from "../pages/Admin/Users/Users"

// import { Error404 } from "../pages/404/404"
// import { VendorHome } from "../pages/Vendors/VendorsDashboard/VendorsDashboard"
// import { VendorProfile } from "../pages/Vendors/VendorProfile/VendorProfile"

const Home = React.lazy(() =>
  import('../pages/Home/Home').then(module => ({ default: module.PHome }))
);
const IRQ = React.lazy(() =>
  import('../pages/Risk-Evaluate-IRQ/IRQ').then(module => ({ default: module.IRQ }))
);
const IRQReport = React.lazy(() =>
  import('../pages/Risk-Evaluate-IRQ/DetailedReport').then(module => ({ default: module.PrivacIRQReport }))
);
const SAQ = React.lazy(() =>
  import('../pages/Risk-Assess-SAQ/SAQ').then(module => ({ default: module.SAQ }))
);
const Users = React.lazy(() =>
  import('../pages/Admin/Users/Users').then(module => ({ default: module.PrUsers }))
);
const Error404 = React.lazy(() =>
  import('../pages/404/404').then(module => ({ default: module.PrivaError404 }))
);
const VendorHome = React.lazy(() =>
  import('../pages/Vendors/VendorsDashboard/VendorsDashboard').then(module => ({ default: module.PrivacyVendorHome }))
);
const VendorProfile = React.lazy(() =>
  import('../pages/Vendors/VendorProfile/VendorProfile').then(module => ({ default: module.PrivacyPolVendorProfile }))
);

export const Authenticated = () => {
    return (
        <ThemeProvider theme={TDTheme}>
            <GlobalsStyle />

            <Sidebar />

            <ContentWrapper>
            <Suspense fallback={<div></div>}>
                <Switch>
                    <Route component={Home} exact path="/home" />

                    {/*  EVALUATE  ////////////////////////////////////  */}
                    <Route component={IRQ} exact path="/evaluate" />

                    <Route component={IRQReport} path="/evaluate/report" />

                    {/*  ASSESS  //////////////////////////////////////  */}
                    <Route component={SAQ} path="/assess" />

                    {/*  Vendors  =====================================  */}
                    <Route component={VendorHome} exact path="/vendors" />

                    <Route
                        component={VendorProfile}
                        path="/vendors/:id/evaluate"
                    />

                    {/*  ADMIN  ///////////////////////////////////////  */}
                    <Route component={Users} path="/users" />

                    <Route component={Error404} />
                </Switch>
                </Suspense>
            </ContentWrapper>
        </ThemeProvider>
    )
}
