import styled from "styled-components"

import { defaultColours as baseColors } from "fictoan-react"

export const LoginStyled = styled.article`
    background-color: ${baseColors.slate10};

    #resend-code {
        margin-top: -24px;
    }
`
