import React, { useState, useEffect } from "react"
import { getEncryptedData, getDecryptedData } from "../../../lib/encryption"
import { useHistory } from "react-router-dom"
import { Link, useLocation } from "react-router-dom"
// import { browserName, MobileView, isBrowser, isMobile } from 'react-device-detect';
import * as rdd from 'react-device-detect';

import {
    Element,
    Row,
    Portion,
    FormWrapper,
    InputField,
    Button,
    Card,
    Heading,
    Text,
    HRule,
    FormItem,
    NotificationItem,
    NotificationsWrapper,
} from "fictoan-react"

import { LoginStyled } from "./Login.styled"
import LogoTitanDef from "../../../assets/images/TD_Blue_Logo.svg"
import { withPageDetails } from "../../../assets/helpers/Page"
import qs from "../../../assets/helpers/qs"

import axios from "axios"
import CONSTANTS from "../../../lib/constants"
import ReCaptcha from "../../../lib/captcha"
import { setItem, getItem, removeAll } from "../../../lib/myStore"
import moment from "moment"

let recaptchaInvisibleInstance

const Page = () => {
    let history = useHistory()
    const [values, setValues] = useState({
        email: "",
        password: "",
    })
    const [isCreateAccount, setIsCreateAccount] = useState(false)
    const [isLoginError, setIsLoginError] = useState(false)
    const [resetLinkMessage, setResetLinkSuccessMsg] = useState(false)
    const [resetLinkErrorMsg, setResetLinkErrorMsg] = useState(false)
    const [isInvalidCrediantials, setIsInvalidCrediantials] = useState(false)
    const [isLoginErrorMsg, setIsLoginErrorMsg] = useState("")
    const [isEmailInvalidMsg, setIsEmailInvalidMsg] = useState("")
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [isValid2FAPin, setIsValid2FAPin] = useState(true)
    const [passwordErrorMsg, setPasswordErrorMsg] = useState("")
    const [isCaptchResponse, setIsCaptchResponse] = useState(false)
    const [captchaResponse, setCaptchaResponse] = useState("")
    const regex = /^((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
    const mfaRegex = /^[0-9]{6}$/
    const location = useLocation()
    const { out } = qs(location.search)
    let [notification, setNotification] = useState({
        visible: false,
        message: "",
        type: "success",
    })
    let [token, setToken] = useState(getItem("token"))

    let [pin, set2FAPin] = useState(false)
    let [loginResponse, setLoginResponse] = useState(false)
    let [isSmsAuthenticator, setIsSmsAuthenticator] = useState(false)
    let [isGoogleAuthenticator, setIsGoogleAuthenticator] = useState(false)
    let [isDuoMobile, setIsduoMobile] = useState(false)

    const [resendActivated, setResendActivated] = useState(false);

    const [errorMessage, setErrorMessage] = React.useState('');
    const [isErrorShown, setErrorShown] = React.useState(false);

    useEffect(() => {
        if (token && Object.keys(token).length) {
            const request = axios.get(`${CONSTANTS.API_URL}/api/v1/validateSessionByToken/${token}`)
            request
                .then((response) => {
                    let res = getDecryptedData(response.data)
                    setItem("token", res.token)
                    setItem("userInfo", res.user)
                    // if (res.subscription) {
                    //     // let subData = getEncryptedData(res.subscription);
                    //     setItem("subscription", res.subscription)
                    // }
                    // if (!Response.user.isMSPUser && Response.user.userType !== 'MSP' && Response.user.role === 'Vendor Management' ) {
                    //     history.push("/vendors")
                    // } else if (Response.user.role === 'titanDefAdmin' || Response.user.isMSPUser || Response.user.userType === 'MSP') {
                    //     history.push("/mspDashboard")
                    // } else {
                    //     history.push("/home")
                    // }
                    if (!Response.user.isMSPUser && Response.user.userType !== 'MSP' && Response.user.role === 'Vendor Management' ) {
                        if ( Response.user.subscriptionType && Response.user.subscriptionType === "Compliance" && (Response.user.compliances && Response.user.compliances.length)) {
                            if (Response.user.compliances.includes('FFIEC') && Response.user.compliances.includes('FINRA')) {
                                history.push("/compliance/ffiec")
                            }
                            if (Response.user.compliances.includes('FFIEC') && !Response.user.compliances.includes('FINRA')) {
                                history.push("/compliance/ffiec")
                            }
                            if (Response.user.compliances.includes('FINRA') && !Response.user.compliances.includes('FFIEC')) {
                                history.push("/compliance/finra")
                            }
                        } else {
                            history.push("/vendors")
                        }

                    } else if (Response.user.role === 'titanDefAdmin' || Response.user.isMSPUser || Response.user.userType === 'MSP') {
                        if ( Response.user.subscriptionType && Response.user.subscriptionType === "Compliance" && (Response.user.compliances && Response.user.compliances.length)) {
                            if (Response.user.compliances.includes('FFIEC') && Response.user.compliances.includes('FINRA')) {
                                history.push("/compliance/ffiec")
                            }
                            if (Response.user.compliances.includes('FFIEC') && !Response.user.compliances.includes('FINRA')) {
                                history.push("/compliance/ffiec")
                            }
                            if (Response.user.compliances.includes('FINRA') && !Response.user.compliances.includes('FFIEC')) {
                                history.push("/compliance/finra")
                            }
                        } else {
                            history.push("/mspDashboard")
                        }
                    } else {
                        if ( Response.user.subscriptionType && Response.user.subscriptionType === "Compliance" && (Response.user.compliances && Response.user.compliances.length)) {
                            if (Response.user.compliances.includes('FFIEC') && Response.user.compliances.includes('FINRA')) {
                                history.push("/compliance/ffiec")
                            }
                            if (Response.user.compliances.includes('FFIEC') && !Response.user.compliances.includes('FINRA')) {
                                history.push("/compliance/ffiec")
                            }
                            if (Response.user.compliances.includes('FINRA') && !Response.user.compliances.includes('FFIEC')) {
                                history.push("/compliance/finra")
                            }
                        } else {
                            if (loginResponse.user.isKillSwitchOn) {
                                history.push("/admin/org-profile/subscription-details")
                            } else {
                                history.push("/home")
                            }
                            // history.push("/home")
                        }
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        history.push("/")
                        removeAll()
                    }
                })
        }
    }, [])
    // useEffect(() => {
    //   if (token && Object.keys(token).length){
    //     return axios
    //       .get(`${CONSTANTS.API_URL}/api/v1/validateSessionByToken/${token}`)
    //       .then(() => {
    //         history.push('/home');
    //         return;
    //       });
    //       // .catch((HTTPException) => {
    //       //   // new Toast('Session Expired.', Toast.TYPE_ERROR, Toast.TIME_LONG);
    //       //   console.error(HTTPException);
    //       //   // stopLoader();
    //       //   removeAll();
    //       //   history.push('/');
    //       // });
    //     // return route('/home');
    //   }
    // }, [token])
    useEffect(() => {
        if (out) {
            setNotification({
                ...notification,
                visible: true,
                message: "Logged out.",
                type: "success",
            })
            setTimeout(() => {
                setNotification({
                    visible: false,
                })
            }, 4000)
        }
    }, [])
    async function login(e) {
        e.preventDefault()
        if (!regex.test(values.email)) {
            return
        }
        let username = values.email
        let password = values.password
        // let grecaptchaResponse= captchaResponse;
        let data = {
            username,
            password,
            deviceInfo: {
                deviceType: rdd.deviceType,
                browserName: rdd.browserName,
                osName: rdd.osName,
            }
        }
        let encryptedData = await getEncryptedData(data)
        axios
            .post(`${CONSTANTS.API_URL}/api/v1/login`, encryptedData)
            .then(async (response) => {
                let Response = await getDecryptedData(response.data)
                setLoginResponse(Response)
                setIsLoginError(false)
                setIsLoginErrorMsg("")
                setIsInvalidCrediantials(false)
                // setItem('token', Response.token);
                if (Response.user && Response.user.isMFAConfigured && Response.user.smsAuthenticator) {
                  sendTOTPOnSms(null,Response.user.uuid)
                  let smsinput = document.getElementById("smsotp");
                  if (smsinput) smsinput.focus();
                  setIsSmsAuthenticator(true)
                  return;
                } else if (Response.user && Response.user.isMFAConfigured && Response.user.googleAuthenticator) {
                  setIsGoogleAuthenticator(true)
                  return;
                } else if (Response.user && Response.user.isMFAConfigured && Response.user.duoMobile) {
                  setIsduoMobile(true)
                  return;
                } else if ( Response.token ) {
                    // CONSTANTS.SETTINGS.data.email = Response.user.email;
                    // CONSTANTS.SETTINGS.data.role = Response.user.role;
                    // CONSTANTS.SETTINGS.data.displayName = Response.user.displayName;
                    // if (Response.user.role === 'titanDefClient' || Response.user.role === 'Tenant Admin')  {
                    //   CONSTANTS.SETTINGS.data.settings.layout.config.navbar.display = false;
                    // } else {
                    //   CONSTANTS.SETTINGS.data.settings.layout.config.navbar.display = true;
                    // }
                    // Response.user.data = CONSTANTS.SETTINGS.data;
                    // setItem("token", Response.token)
                    setItem("userInfo", Response.user)
                    // if (Response.subscription) {
                    //     // let subData = getEncryptedData(Response.subscription);
                    //     setItem("subscription", Response.subscription)
                    // }

        //             let data = {
        //                 tenantID: Response.user.tenantID,
        //                 tenantDisplayName: Response.user.tenantDisplayName,
        //                 typeOfTenant: Response.user.userType,
        //                 userID: Response.user.uuid,
        //                 deviceInfo: {
        //                     deviceType: rdd.deviceType,
        //                     browserName: rdd.browserName,
        //                     osName: rdd.osName,
        //                 }
        //             }
        //             console.log(data,'%%%%%%%%%%%%%%');
        //             let encryptedUsagesStatisticData = await getEncryptedData(data)
        // axios.post(`${CONSTANTS.API_URL}/api/v1/usagesStatistic`, encryptedUsagesStatisticData).then((res) => {
        //     console.log(res,'$$$$$$$$$$$$$$$$44');
        // })
                    if (!Response.user.isMSPUser && Response.user.userType !== 'MSP' && Response.user.role === 'Vendor Management' ) {
                        if ( Response.user.subscriptionType && Response.user.subscriptionType === "Compliance" && (Response.user.compliances && Response.user.compliances.length)) {
                            if (Response.user.compliances.includes('FFIEC') && Response.user.compliances.includes('FINRA')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/ffiec")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                            if (Response.user.compliances.includes('FFIEC') && !Response.user.compliances.includes('FINRA')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/ffiec")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                            if (Response.user.compliances.includes('FINRA') && !Response.user.compliances.includes('FFIEC')) {
                                if (Response.user.isKillSwitchOn) {
                                history.push("/admin/org-profile/subscription-details")
                            } else {
                                history.push("/compliance/finra")
                            }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                        } else {
                            if (Response.user.isKillSwitchOn) {
                                history.push("/admin/org-profile/subscription-details")
                            } else {
                                history.push("/vendors")
                            }
                            if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                        }

                    } else if (Response.user.role === 'titanDefAdmin' || Response.user.isMSPUser || Response.user.userType === 'MSP') {
                        if ( Response.user.subscriptionType && Response.user.subscriptionType === "Compliance" && (Response.user.compliances && Response.user.compliances.length)) {
                            if (Response.user.compliances.includes('FFIEC') && Response.user.compliances.includes('FINRA')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/ffiec")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                            if (Response.user.compliances.includes('FFIEC') && !Response.user.compliances.includes('FINRA')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/ffiec")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                            if (Response.user.compliances.includes('FINRA') && !Response.user.compliances.includes('FFIEC')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/finra")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                        } else {
                            if (Response.user.isMSPUserMFAActivated) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/mspAdmin/profile/2fa")
                                }
                                // window.location.reload();
                            } else {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/mspDashboard")
                                }

                            }
                            if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                        }
                    } else {
                        if ( Response.user.subscriptionType && Response.user.subscriptionType === "Compliance" && (Response.user.compliances && Response.user.compliances.length)) {
                            if (Response.user.compliances.includes('FFIEC') && Response.user.compliances.includes('FINRA')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/ffiec")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                            if (Response.user.compliances.includes('FFIEC') && !Response.user.compliances.includes('FINRA')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/ffiec")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                            if (Response.user.compliances.includes('FINRA') && !Response.user.compliances.includes('FFIEC')) {
                                if (Response.user.isKillSwitchOn) {
                                    history.push("/admin/org-profile/subscription-details")
                                } else {
                                    history.push("/compliance/finra")
                                }
                                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                            }
                        } else {
                            // history.push("/home")
                            if (Response.user.isKillSwitchOn) {
                                history.push("/admin/org-profile/subscription-details")
                            } else {
                                history.push("/home")
                            }
                            if (Response.user && Response.user.subscriptionValidTill) {
                                getSubscriptionExpireDate(Response)
                            }
                        }
                    }
                    // if (Response.user && Response.user.subscriptionValidTill) {
                    //     getSubscriptionExpireDate(Response)
                    // }
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setIsLoginError(true)
                    if (error.response.data.statusCode === 702) {
                        setResetLinkErrorMsg(true)
                    } else {
                        if (error.response.data.statusCode === 700) {
                            setIsInvalidCrediantials(true)
                        } else {
                            setIsInvalidCrediantials(false)
                        }
                        setIsLoginErrorMsg(error.response.data.message)
                    }
                    recaptchaInvisibleInstance && recaptchaInvisibleInstance.reset()
                    setIsCaptchResponse(false)
                    setCaptchaResponse("")
                } else {
                    setIsLoginError(true)
                    setIsLoginErrorMsg("Something Went Wrong")
                    setIsInvalidCrediantials(false)
                }
            })
    }

      /*
    Created By:- Manohar Sule,
    Created On:- 20-08-2020,
    Comment:-  if smsAuthenticator on
    */

   function getSubscriptionExpireDate(Response) {
    let userInfo = getItem("userInfo")
    userInfo['isOnlyReadAccess'] = false;

    // console.log('subscription end msg');
        let subscriptionExpireDate = Response.user.subscriptionValidTill ? moment(Response.user.subscriptionValidTill).format("YYYY-MM-DD") : '';
        var given = moment(subscriptionExpireDate, "YYYY-MM-DD");
        var expireDate = Response.user.subscriptionValidTill ? moment(Response.user.subscriptionValidTill).format("DD MMM YYYY") : '';

        var current = moment().startOf('day');
        //Difference in number of days
        // console.log(subscriptionExpireDate,'subscriptionExpireDatesubscriptionExpireDatesubscriptionExpireDate');
        let days = moment.duration(given.diff(current)).asDays();
        // console.log(days,'subscriptionExpireDate');
        if (days <= 60) {
            if (days < 0 ) {
                if (userInfo.subscriptionType === 'Assessment') {
                    userInfo['isOnlyReadAccess'] = true;
                }
                let d2 = document.getElementById('subscriptionEnd');
                if (d2) {
                    let d3 = document.getElementById('expireDays');

                    // let text = 'Your subscription has expired. Please renew it as soon as possible. ';
                    let text = `Your subscription has expired on ${expireDate}.`
                    d3.innerHTML = text;
                    d2.style.display = 'block';
                }

            } else {
                let d2 = document.getElementById('subscriptionEnd');
                if (d2) {
                    let d3 = document.getElementById('expireDays');
                    // let text = '';
                    let text = `Your subscription is about to expire on ${expireDate}.`

                    // if (days === 0) {
                    //     text = 'Your subscription is expire today. Please renew it as soon as possible. ';
                    // }
                    // if (days === 1) {
                    //     text = 'Your subscription will expire in ' + days + ' day. Please renew it as soon as possible. ';
                    // }
                    // if (days > 1) {
                    //     text = 'Your subscription will expire in ' + days + ' days. Please renew it as soon as possible. ';
                    // }
                    d3.innerHTML = text;
                    d2.style.display = 'block';
                }
            }

        } else {
            let d2 = document.getElementById('subscriptionEnd');
            if (d2) d2.style.display = 'none';
        }
        setItem("userInfo", userInfo)
   }

    function sendTOTPOnSms(e,userID){
      if(e) e.preventDefault();
      let encryptedData = getEncryptedData({userID});
      axios.put(`${CONSTANTS.API_URL}/api/v2/sendTOTPOnSmsV2`,encryptedData
      ).then( response => {
        setResendActivated(false);
          let decryptedResponse = getDecryptedData(response.data);
          setTimeout(() =>{
            setResendActivated(true);
          },1*60*1000)
        }
      )
    }

    async function login2FA(e) {
      e.preventDefault();
      return new Promise(async (resolve, reject) => {
        let encryptedData = await getEncryptedData({
          totp: pin,
          userID: loginResponse.user.uuid,
          deviceInfo: {
            deviceType: rdd.deviceType,
            browserName: rdd.browserName,
            osName: rdd.osName,
        }
        });
        setErrorMessage('');
        setErrorShown(false);
        axios.put(`${CONSTANTS.API_URL}/api/v2/varifyTOTPSmsAuthenticator`,
          encryptedData
        ).then( response => {
          setItem('token', response.data.token);
          setItem('userInfo', loginResponse.user);
        //   history.push('/home');
        //   if (!loginResponse.user.isMSPUser && loginResponse.user.userType !== 'MSP' && loginResponse.user.role === 'Vendor Management' ) {
        //     history.push("/vendors")
        //   } else if (loginResponse.user.role === 'titanDefAdmin' || loginResponse.user.isMSPUser || loginResponse.user.userType === 'MSP') {
        //     history.push("/mspDashboard")
        //   } else {
        //     history.push("/home")
        //   }
        if (!loginResponse.user.isMSPUser && loginResponse.user.userType !== 'MSP' && loginResponse.user.role === 'Vendor Management' ) {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                        history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                        history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                        history.push("/compliance/finra")
                    }
                }
            } else {
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
                if (loginResponse.user.isKillSwitchOn) {
                    history.push("/admin/org-profile/subscription-details")
                } else {
                    history.push("/vendors")
                }
            }

        } else if (loginResponse.user.role === 'titanDefAdmin' || loginResponse.user.isMSPUser || loginResponse.user.userType === 'MSP') {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/finra")
                    }
                }
            } else {
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
                if (loginResponse.user.isKillSwitchOn) {
                    history.push("/admin/org-profile/subscription-details")
                } else {
                history.push("/mspDashboard")
                }
            }
        } else {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/finra")
                    }
                }
            } else {
                if (loginResponse.user.isKillSwitchOn) {
                    history.push("/admin/org-profile/subscription-details")
                } else {
                    history.push("/home")
                }
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
                // history.push("/home")
            }
        }
        }).catch(error => {
          if(error.response && error.response.data) {
            setErrorShown(true);
            setErrorMessage(error.response.data.message);
            reject(error.response.data.message);
          } else {
            reject('Something Went Wrong');
          }
        });
    });
  }

  /*
  Created By:- Manohar Sule,
  Created On:- 20-08-2020,
  Comment:-  if googleAuthenticator on
  */

  async function verifyGooleAuthentication(e) {
    e.preventDefault();
    return new Promise(async (resolve, reject) => {
      let encryptedData = await getEncryptedData({
        totp: pin,
        userID: loginResponse.user.uuid,
        deviceInfo: {
            deviceType: rdd.deviceType,
            browserName: rdd.browserName,
            osName: rdd.osName,
        }
      });
      setErrorMessage('');
      setErrorShown(false);
      axios.put(`${CONSTANTS.API_URL}/api/v2/varifyTOTPGoogleAuthenticator`,
        encryptedData
      ).then( response => {
        setItem('token', response.data.token);
        setItem('userInfo', loginResponse.user);
        // history.push('/home');
        // if (!loginResponse.user.isMSPUser && loginResponse.user.userType !== 'MSP' && loginResponse.user.role === 'Vendor Management' ) {
        //     history.push("/vendors")
        //   } else if (loginResponse.user.role === 'titanDefAdmin' || loginResponse.user.isMSPUser || loginResponse.user.userType === 'MSP') {
        //     history.push("/mspDashboard")
        //   } else {
        //     history.push("/home")
        //   }
        if (!loginResponse.user.isMSPUser && loginResponse.user.userType !== 'MSP' && loginResponse.user.role === 'Vendor Management' ) {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {

                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                        history.push("/compliance/ffiec")
                    }

                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                        history.push("/compliance/ffiec")
                    }

                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    history.push("/compliance/finra")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
            } else {
                history.push("/vendors")
                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
            }

        } else if (loginResponse.user.role === 'titanDefAdmin' || loginResponse.user.isMSPUser || loginResponse.user.userType === 'MSP') {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    history.push("/compliance/ffiec")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    history.push("/compliance/ffiec")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    history.push("/compliance/finra")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
            } else {
                history.push("/mspDashboard")
                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
            }
        } else {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    history.push("/compliance/ffiec")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    history.push("/compliance/ffiec")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    history.push("/compliance/finra")
                    if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
                }
            } else {
                if (loginResponse.user.isKillSwitchOn) {
                    history.push("/admin/org-profile/subscription-details")
                } else {
                    history.push("/home")
                }
                if (Response.user && Response.user.subscriptionValidTill) {
                                    getSubscriptionExpireDate(Response)
                                }
            }
        }
      }).catch(error => {
        if(error.response && error.response.data) {
          setErrorShown(true);
          setErrorMessage(error.response.data.message);
          reject(error.response.data.message);
        } else {
          reject('Something Went Wrong');
        }
      });
  });
  }

  /*
  Created By:- Manohar Sule,
  Created On:- 20-08-2020,
  Comment:-  if duoMobile on
  */

  async function verifyDuoAuthentication(e) {
    e.preventDefault();
    return new Promise(async (resolve, reject) => {
      let encryptedData = await getEncryptedData({
        totp: pin,
        userID: loginResponse.user.uuid,
        deviceInfo: {
            deviceType: rdd.deviceType,
            browserName: rdd.browserName,
            osName: rdd.osName,
        }
      });
      setErrorMessage('');
      setErrorShown(false);
      axios.put(`${CONSTANTS.API_URL}/api/v2/varifyTOTPDuoMobile`,
        encryptedData
      ).then( response => {
        setItem('token', response.data.token);
        setItem('userInfo', loginResponse.user);
        // history.push('/home');
        // if (!loginResponse.user.isMSPUser && loginResponse.user.userType !== 'MSP' && loginResponse.user.role === 'Vendor Management' ) {
        //     history.push("/vendors")
        //   } else if (loginResponse.user.role === 'titanDefAdmin' || loginResponse.user.isMSPUser || loginResponse.user.userType === 'MSP') {
        //     history.push("/mspDashboard")
        //   } else {
        //     history.push("/home")
        //   }
        if (!loginResponse.user.isMSPUser && loginResponse.user.userType !== 'MSP' && loginResponse.user.role === 'Vendor Management' ) {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/finra")
                    }
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                }
            } else {
                history.push("/vendors")
            }

        } else if (loginResponse.user.role === 'titanDefAdmin' || loginResponse.user.isMSPUser || loginResponse.user.userType === 'MSP') {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/finra")
                    }
                    if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                        getSubscriptionExpireDate(loginResponse)
                    }
                }
            } else {
                if (loginResponse.user.isKillSwitchOn) {
                    history.push("/admin/org-profile/subscription-details")
                } else {
                history.push("/mspDashboard")
                }
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
            }
        } else {
            if ( loginResponse.user.subscriptionType && loginResponse.user.subscriptionType === "Compliance" && (loginResponse.user.compliances && loginResponse.user.compliances.length)) {
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
                if (loginResponse.user.compliances.includes('FFIEC') && loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FFIEC') && !loginResponse.user.compliances.includes('FINRA')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/ffiec")
                    }
                }
                if (loginResponse.user.compliances.includes('FINRA') && !loginResponse.user.compliances.includes('FFIEC')) {
                    if (loginResponse.user.isKillSwitchOn) {
                        history.push("/admin/org-profile/subscription-details")
                    } else {
                    history.push("/compliance/finra")
                    }
                }
            } else {
                if (loginResponse.user.isKillSwitchOn) {
                    history.push("/admin/org-profile/subscription-details")
                } else {
                    history.push("/home")
                }
                if (loginResponse.user && loginResponse.user.subscriptionValidTill) {
                    getSubscriptionExpireDate(loginResponse)
                }
            }
        }
      }).catch(error => {
        if(error.response && error.response.data) {
          setErrorShown(true);
          setErrorMessage(error.response.data.message);
          reject(error.response.data.message);
        } else {
          reject('Something Went Wrong');
        }
      });
  });
  }

    function validateEmailCriterion(e) {
        e.preventDefault()
        if (e.target.value){
            setIsEmailValid(regex.test(e.target.value))
            if (regex.test(e.target.value)) {
                setIsEmailInvalidMsg("")
            } else {
                setIsEmailInvalidMsg("This is not a valid Email/Mobile number")
            }
        } else {
            setIsEmailValid(true)
            setIsEmailInvalidMsg("")
        }

    }

    function validatePassword(e) {
        e.preventDefault()
        if (e.target.value) {
            setPasswordErrorMsg("")
        } else {
            setPasswordErrorMsg("Please Enter Password")
        }
    }

    function valid2FAPin(e) {
        e.preventDefault()
        // console.log(mfaRegex.test(e.target.value),'mfaRegex.test(e.target.value)');
        if (e.target.value) {
            setIsValid2FAPin(mfaRegex.test(e.target.value))

        } else {
            setIsValid2FAPin(true)
        }
    }

    const expiredCallback = () => {
        setIsCaptchResponse(false)
        setCaptchaResponse("")
    }
    // const changeCallback = (response) => {
    //   setCaptchaResponse(response);
    //   setIsCaptchResponse(true);
    // };

    const handleChange = (e) => {
        e.persist()
        setValues((values) => ({
            ...values,
            [e.target.name]: e.target.value,
        }))
    }
    function resendLink() {
        // e.preventDefault()
        let payload = {
            userEmail: values.email,
            typeOfEmail: "inviteUser",
        }
        let encryptedPayload = getEncryptedData(payload)
        const request = axios.post(`${CONSTANTS.API_URL}/api/v1/verifyUser`, encryptedPayload)
        request
            .then((response) => {
                let user = response.data
                setIsLoginError(false)
                setResetLinkSuccessMsg(true)
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setIsLoginError(true)
                    setIsLoginErrorMsg(error.response.data.message)
                    setResetLinkErrorMsg(false)
                }
            })
    }

    return (
        <LoginStyled>
            <Row sidePadding="small" paddingTop="tiny">
                <Portion desktopSpan="2">
                    <img src={LogoTitanDef} alt="LogoTitanDef" />
                </Portion>
            </Row>

            <Row sidePadding="small">
                <Portion desktopSpan="11">
                    <Heading as="h4" weight="400" marginTop="medium">
                        Welcome to
                    </Heading>
                    <Heading as="h1">
                        TitanDef
                        {/* <span style={{ fontSize: "0.6em" }}>Beta</span> */}
                    </Heading>
                    <Text size="large" marginBottom="tiny">
                        TitanDef is a cyber governance, compliance and risk management platform designed to help
                        businesses define their “cyber security risk posture” and attain efficacy in prioritizing
                        security investments by using a scientific risk management approach.
                    </Text>
                    <Text size="large" marginBottom="tiny">
                        TitanDef packages cyber risk assessment methodology and supporting “plan of action” workflows
                        for small/medium sized businesses to achieve quantifiable and measurable cyber improvements. The
                        platform also provides third party cyber risk management life-cycle by evaluating individual
                        risk relationships with vendors, managing vendor assessments and computes standardized risk
                        rankings across vendors.
                    </Text>

                    {/*<a href="https://titandef.com/">Learn more &rarr;</a>*/}
                </Portion>

                <Portion desktopSpan="6" />

                <Portion desktopSpan="7">
                    <Card padding="medium" shape="rounded" bgColor="white" shadow="soft">
                        <FormWrapper>
                            <Heading as="h6" marginBottom="tiny">
                                Login
                            </Heading>
                            {
                                /*
                                    BugHerd Issue #T909
                                    Issue: tick mar on password field doesnt make sense. it giving the impression to the user they have entered the correct password.
                                    Developer: Manohar sule
                                    date: 29/06/2021
                                    */
                            }

                            <InputField
                                required
                                //validateThis
                                // pattern='^((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|([0|\+[0-9]{1,5})?([7-9][0-9]{9})$}'
                                // type="email"
                                autoFocus
                                id="email"
                                label="Email or mobile"
                                errorText="Must be a valid email"
                                name="email"
                                onChange={(e) => {
                                    handleChange(e)

                                }}
                                onBlur={(e) => {
                                    validateEmailCriterion(e)
                                }}
                                disabled={isSmsAuthenticator || isGoogleAuthenticator || isDuoMobile}
                            />
                            {isEmailInvalidMsg && (
                                <Text className="text-red" size="tiny">
                                    {isEmailInvalidMsg}
                                </Text>
                            )}

                            <InputField
                                required
                                id='password'
                                type="password"
                                label="Password"
                                errorText="Password seems wrong"
                                //validateThis
                                name="password"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onBlur={(e) => {
                                    validatePassword(e)
                                }}
                                disabled={isSmsAuthenticator || isGoogleAuthenticator || isDuoMobile}
                            />
                            {passwordErrorMsg && (
                                <Text className="text-red" size="tiny">
                                    {passwordErrorMsg}
                                </Text>
                            )}

                            {isSmsAuthenticator &&
                            <div>
                            <InputField
                            id='smsotp'
                                required
                                autoFocus
                                // validateThis
                                type="text"
                                label="2FA SMS code"
                                // helpText="Please check your mobile"
                                //errorText={ isValid2FAPin ? 'eeeeeeee' : "Incorrect 2FA" }
                                // pattern="[0-9]{6}"
                                onChange={ (e) => {
                                  set2FAPin(e.target.value);
                                }}
                                onBlur={(e) => {
                                    if (e.target.value) {
                                        setIsValid2FAPin(mfaRegex.test(e.target.value))
                                    } else {
                                        setIsValid2FAPin(true)
                                    }
                                }}
                            />
                            <Text className="" size="small" style={{marginTop: '-20px'}}>
                                    {'Please check your mobile'}
                                </Text>

                            {!isValid2FAPin && pin.length !== 6 && (
                                <Text className="text-red" size="small" style={{marginTop: '-20px'}}>
                                    {'Incorrect 2FA'}
                                </Text>
                            )}

                            {isErrorShown && errorMessage && (
                                <Text className="text-red" size="small">
                                    {errorMessage}
                                </Text>
                            )}

                            {/* <Button className="font-medium" onClick={(e) =>{
                              sendTOTPOnSms(e,loginResponse.user.uuid)
                            }} style={{cursor:"pointer",backgroundColor:"transparent", paddingLeft: 0}} disabled={!resendActivated}>
                                Resend Code
                            </Button> */}

                            {
                                // !resendActivated && (
                                //     <p style={{fontSize:"12px",color:"red"}}>*Resend button will be activated after 1 minutes</p>
                                // )
                            }

                            </div>
                            }
                            {
                              isGoogleAuthenticator &&
                              <div>
                                <InputField
                                    required
                                    // validateThis
                                    type="text"
                                    label="2FA Authenticator Code"
                                    helpText="Please check your mobile"
                                    //errorText="Incorrect 2FA"
                                    //pattern="[0-9]{6}"
                                    onChange={ (e) => {
                                      set2FAPin(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value) {
                                            setIsValid2FAPin(mfaRegex.test(e.target.value))
                                        } else {
                                            setIsValid2FAPin(true)
                                        }
                                    }}
                                />
                                {!isValid2FAPin && pin.length !== 6 && (
                                    <Text className="text-red" size="small" style={{marginTop: '-20px'}}>
                                        {'Incorrect 2FA'}
                                    </Text>
                                )}
                                {isErrorShown && errorMessage && (
                                    <Text className="text-red" size="small">
                                        {errorMessage}
                                    </Text>
                                )}
                                </div>
                            }
                            {
                              isDuoMobile &&
                              <div>
                                <InputField
                                    required
                                    // validateThis
                                    type="text"
                                    label="2FA Authenticator Code"
                                    helpText="Please check your mobile"
                                    //errorText="Incorrect 2FA"
                                    //pattern="[0-9]{6}"
                                    onChange={ (e) => {
                                      set2FAPin(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value) {
                                            setIsValid2FAPin(mfaRegex.test(e.target.value))
                                        } else {
                                            setIsValid2FAPin(true)
                                        }
                                    }}
                                />
                                {!isValid2FAPin && pin.length !== 6 && (
                                    <Text className="text-red" size="small" style={{marginTop: '-20px'}}>
                                        {'Incorrect 2FA'}
                                    </Text>
                                )}
                                {isErrorShown && errorMessage && (
                                    <Text className="text-red" size="small">
                                        {errorMessage}
                                    </Text>
                                )}
                              </div>
                            }
                            {/*<ReCaptcha ref={e => recaptchaInvisibleInstance = e} sitekey={CONSTANTS.siteKey}
                                 render="explicit" onChange={changeCallback} onExpired={expiredCallback}/>*/}

                            {/*<Element id="resend-code" as="div">
                                 <Link to="/" className="text-small weight-400">
                                     Resend code
                                 </Link>
                             </Element>*/}

                            {isLoginError && isLoginErrorMsg && !isInvalidCrediantials && (
                                <Text className="text-red" size="small" marginTop="tiny">
                                    {isLoginErrorMsg}
                                </Text>
                            )}
                            {isLoginError && isInvalidCrediantials && (
                                <Text className="text-red" size="small" marginTop="tiny">
                                    You have entered invalid credentials. <br /> Please try again
                                </Text>
                            )}
                            {isLoginError && resetLinkErrorMsg && (
                                <Text size="small" marginTop="tiny">
                                    Your account is not verified.
                                    <br />
                                    To verify your account, please use the link in your mailbox.
                                    <br />
                                    If you did not receive any link, click{" "}
                                    <a
                                        onClick={() => {
                                            resendLink()
                                        }}
                                    >
                                        {" "}
                                        here{" "}
                                    </a>{" "}
                                    to request a new one
                                </Text>
                            )}
                            {!isLoginError && resetLinkMessage && (
                                <Text className="text-green" size="small" marginTop="tiny">
                                    An email has been sent to your registered email ID
                                </Text>
                            )}

                            <div className="push-to-ends vertically-center-items margin-top-tiny">
                                <span>
                                    {(!isSmsAuthenticator && !isGoogleAuthenticator && !isDuoMobile) && (
                                        <Button
                                            type="submit"
                                            shape="rounded"
                                            kind="primary"
                                            shadow="mild"
                                            onClick={login}
                                            disabled={!values.email || !values.password}
                                        >
                                            LOGIN
                                        </Button>
                                    )}
                                    {isSmsAuthenticator && (
                                        <Button
                                            type="submit"
                                            shape="rounded"
                                            kind="primary"
                                            shadow="mild"
                                            disabled={!(pin.length === 6)}
                                            onClick={login2FA}
                                        >
                                            SUBMIT
                                        </Button>
                                    )}
                                    { isGoogleAuthenticator && <Button
                                        type="submit"
                                        shape="rounded"
                                        bgColor="violet"
                                        textColor="white"
                                        shadow="mild"
                                        disabled={!(pin.length === 6)}
                                        onClick={verifyGooleAuthentication}
                                    >
                                        SUBMIT
                                    </Button>}
                                    { isDuoMobile && <Button
                                        type="submit"
                                        shape="rounded"
                                        bgColor="violet"
                                        textColor="white"
                                        shadow="mild"
                                        disabled={!(pin.length === 6)}
                                        onClick={verifyDuoAuthentication}
                                    >
                                        SUBMIT
                                    </Button>}
                                </span>
                                {
                                    !isSmsAuthenticator && (
                                        <Link style={{color: '#2874a6'}} to="/forgot-password" className="weight-400">
                                        Forgot password?
                                    </Link>
                                    )
                                }
                                {
                                   isSmsAuthenticator && (
                                    <a title={!resendActivated ? 'Resend button will be activated after 1 minute' : ''} className="font-medium" onClick={(e) =>{
                                        if (resendActivated) {
                                            sendTOTPOnSms(e,loginResponse.user.uuid)
                                        }

                                      }} style={{cursor:resendActivated ? "pointer" : 'default',backgroundColor:"transparent", paddingLeft: 0, color: resendActivated ? '#2874a6' : '#b0bec7', fontWeight: '400'}} disabled={!resendActivated}>
                                          Resend Code
                                      </a>
                                   )
                                }



                            </div>
                        </FormWrapper>

                        {/* <HRule marginTop="micro" marginBottom="micro" /> */}
                        {isCreateAccount && (
                            <Heading as="h6" weight="400">
                                First time here?{`\u00A0`}
                                <Link to="/sign-up">Create an account</Link>.
                            </Heading>
                        )}
                        {/* <Heading as="h6" weight="400">
                            First time here?{`\u00A0`}
                            <Link to="/sign-up">Create an account</Link>.
                        </Heading> */}
                    </Card>
                </Portion>
            </Row>
            {notification.visible && (
                <NotificationsWrapper position="left" anchor="bottom">
                    <NotificationItem type={notification.type || "success"}>{notification.message}</NotificationItem>
                </NotificationsWrapper>
            )}
        </LoginStyled>
    )
}

export const Login = withPageDetails(Page, {
    pageTitle: "Login — Titan Defense",
})
