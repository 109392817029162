import { createGlobalStyle } from 'styled-components';

import IBMPlexSansRegular from "./IBMPlexSans-Regular.ttf";
import IBMPlexSansBold from "./IBMPlexSans-Bold.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: "IBMPlexSansRegular";
        src: local("IBMPlexSansRegular"), url(${IBMPlexSansRegular}) format("truetype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "IBMPlexSansBold";
        src: local("IBMPlexSansBold"), url(${IBMPlexSansBold}) format("truetype");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "IBMPlexSansBold";
        src: local("IBMPlexSansBold"), url(${IBMPlexSansBold}) format("truetype");
        font-weight: 600;
        font-style: normal;
    }
`;